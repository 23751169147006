import React from "react";
import { Row, ProgressBar, Col, Button  } from "react-bootstrap";
import GoalAccodians from "../Pages/Accodians/GoalAccodians";
import Header from "../Pages/Header";
import Sidebar from "../Pages/Sidebar";

import { connect } from "react-redux";
import { goalActions } from "../../actions";
import * as moment from "moment";
import { Link } from "react-router-dom";

class GoalDetailPage extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			modalShow: false,
			open: false,
		};
		this.handleStepValue = this.handleStepValue.bind(this);
	}
	componentDidMount() {
		const id = this.props.match.params.id;
		const { getDetails} = this.props;
    	getDetails(id);
  	}

	handleChangeChk = (event) => {
		if (event.target.checked) {
			let step = {
				isCompleted:true
			}
			this.props.updateGoalSteps(event.target.value, step);
		} else {
			let step = {
				isCompleted:true
			}
			this.props.updateGoalSteps(event.target.value, step);
		}
	}	
    
    // handle input value coming from the child component
	handleStepValue (val) {
		this.setState({ steps: val });
	}

	render() { 
		const { goals } = this.props;
		return (
			<div className="page-wrap-main">
				<Header />

				<div className="wrapper-main">
					<Sidebar />
					<div className="page-content">
						<div className="card--white full-height goal-view">
							<div className="cust-head-center-wrap">
								<Button
									className="back-arrow"
									onClick={() => this.props.history.goBack()}
								></Button>
								<span className="top-heading">{ goals.details ? goals.details.category ? goals.details.category.title : ""  : "" } goal</span>
								<h2 className="cust-heading-center">
									{ goals.details ? goals.details.title : ""  }
								</h2>
							</div>
							{goals.details ? goals.details.steps &&
							<div className="progressbar cust-progressbar cust-progressbar-lg">
								<ProgressBar animated now={Math.round(goals.details.completedStepsAvg)} />
								<span className="per-text">{Math.round(goals.details.completedStepsAvg)}% to goal</span>
							</div>
							: ""}

							<Row className="goal-details-row">
								<Col lg={8} className="goal-details-left">
									<img src={ goals.details ? goals.details.category ? goals.details.category.image : "" : "" } alt="" />
									<div className="wrap-accordians">
										<GoalAccodians goalDetails={ goals.details } />
									</div>
								</Col>
								<Col lg={4} className="goal-details-right">
									{goals.details ? goals.details.steps &&
									<>
										<h2>Steps</h2>
										{ goals.details.steps && goals.details.steps.map((step, index) => (
										<div className={`counter-timeline ${ step.isCompleted ? "inactive-counter-timeline" : ""}` } key={index} >
											<span className="counter">{ index+1 }</span>
											<p>{ step.title }</p>
											<p className="deadline-text"><strong>Deadline:</strong>{ step.deadline ? moment(step.deadline).format("MMMM DD, YYYY") : "N/A" }</p>
											<div className="custom-checkbox"><div><div className="custom-control custom-checkbox">
												<input type="checkbox" id={`step-complete-checkbox${index+1}`} className="custom-control-input" name={`step-${index+1}`} defaultChecked={step.isCompleted} value={step._id} onChange={this.handleChangeChk} />
												<label title="" for={`step-complete-checkbox${index+1}`} className="custom-control-label"></label>
											</div></div></div>
										</div>
										)) }
									</>
									: "" }
									<div className="mt-4">
										<h2>Update Steps:</h2>
										<Link
											className="theme-dark-outline__btn" to={goals.details ? `/goals/edit/${goals.details._id}?step=true` : '#'}
										>
										Update Step
										</Link>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapState(state) {
  const { goals } = state;
  return { goals };
}

const actionCreators = {
  getDetails: goalActions.getDetails,
  getGoalSteps: goalActions.getGoalSteps,
  updateGoalSteps: goalActions.updateGoalSteps,
};

export default connect(mapState, actionCreators)(GoalDetailPage);