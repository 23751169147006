import { journalConstants } from '../constants';

export function journals(state = [], action) {
  switch (action.type) {
    case journalConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case journalConstants.GETALL_SUCCESS:
      return {
        loading: false,
        items: action.journals.data,
        totalNumberOfRows: action.journals.totalNumberOfRows
      };
    case journalConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };
    case journalConstants.LOAD_MORE_REQUEST:
      return {
         ...state,
        loading: true
      };
    case journalConstants.LOAD_MORE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: [ ...(state.items || []), ...action.journal.data ]
      };
      case journalConstants.LOAD_MORE_FAILURE:
        return { 
          error: action.error
        };
       case journalConstants.DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
     case journalConstants.DELETE_SUCCESS:
      return {
      ...state,
      loading: false,
      items: 
      [
          ...state.items.filter(value => value._id !== action.id)
      ]
    };
    case journalConstants.DELETE_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state
  }
}