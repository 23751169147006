//import config from 'config';
import { authHeader } from '../helpers';

export const userService = {
    login,
    logout,
    register,
    forgotPassword,
    get,
    update,
    delete: _delete,
    updateAvatar,
    deleteValue,
    deleteInterest,
    updateValue,
    updateInterest,
    facebookLogin,
    googleLogin,
    linkedinLogin,
    linkedinback,
    getAll,
    getUserRole,
    updateRoleService,
    deleteUser
};

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/auth/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
}

async function  logout() {
    if(localStorage.getItem('user')) {
        const requestOptions = {
            method: 'POST',
            headers: authHeader()
        };
        // remove user from local storage to log user out
        localStorage.clear();
        localStorage.removeItem('user');
        return await fetch(`${process.env.REACT_APP_API_URL}api/auth/users/logout`, requestOptions).then(handleResponse); 
    }
}

function get() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/auth/users/me`, requestOptions).then(handleResponse);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/auth/register`, requestOptions).then(handleResponse);
}

function forgotPassword(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/auth/forgot-password`, requestOptions).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/auth/users/me`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let existing = JSON.parse(localStorage.getItem('user'));
            let newData = {
                "status": existing.status,
                "message": existing.message,
                data: {
                    "firstName": data.data.firstName,
                    "lastName": data.data.lastName,
                    "token": existing.data.token,
                    "values": existing.data.values,
                    "purpose": data.data.purpose,
                    "interests": existing.data.interests,
                    "email": existing.data.email,
                    "avatar": existing.data.avatar,
                    "role": data.data.role
                }
            }
            localStorage.setItem('user', JSON.stringify(newData));
            return data;
    });
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}users/${id}`, requestOptions).then(handleResponse);
}

function updateAvatar(avatar) {
    const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: avatar
            };
    return fetch(`${process.env.REACT_APP_API_URL}api/auth/users/me/avatar`, requestOptions).then(handleResponse).then(data => {
            let existing = JSON.parse(localStorage.getItem('user'));
            console.log( {existing} );
            let newData = {
                "status": existing.status,
                "message": existing.message,
                data: {
                    "firstName": existing.data.firstName,
                    "lastName": existing.data.lastName,
                    "token": existing.data.token,
                    "values": existing.data.values,
                    "purpose": existing.data.purpose,
                    "interests": existing.data.interests,
                    "email": existing.data.email,
                    "avatar": data.data.avatar,
                    "role": existing.data.role,
                }
            }
            localStorage.setItem('user', JSON.stringify(newData));
            return data;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //window.location.reload(true);
            }

            const error = (data.data && data.data[0].msg) || data.message;
            return Promise.reject(error);
        }

        return data;
    });
}

// prefixed function name with underscore because delete is a reserved word in javascript
function deleteValue(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/auth/users/value-delete/${id}`, requestOptions).then(handleResponse).then(data => {
            let existing = JSON.parse(localStorage.getItem('user'));
            let newData = {
                "status": existing.status,
                "message": existing.message,
                data: {
                    "firstName": existing.data.firstName,
                    "lastName": existing.data.lastName,
                    "token": existing.data.token,
                    "values": data.data.values,
                    "purpose": existing.data.purpose,
                    "interests": existing.data.interests,
                    "email": existing.data.email,
                    "avatar": existing.data.avatar,
                }
            }
            localStorage.setItem('user', JSON.stringify(newData));
            return data;
    });
}

function deleteInterest(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/auth/users/interest-delete/${id}`, requestOptions).then(handleResponse).then(data => {
            let existing = JSON.parse(localStorage.getItem('user'));
            let newData = {
                "status": existing.status,
                "message": existing.message,
                data: {
                    "firstName": existing.data.firstName,
                    "lastName": existing.data.lastName,
                    "token": existing.data.token,
                    "values": existing.data.values,
                    "purpose": existing.data.purpose,
                    "interests": data.data.interests,
                    "email": existing.data.email,
                    "avatar": existing.data.avatar,
                }
            }
            localStorage.setItem('user', JSON.stringify(newData));
            return data;
    });
}

function updateValue(values) {
    const requestOptions = {
                method: 'PUT',
                headers: { ...authHeader(), 'Content-Type': 'application/json' },
                body: JSON.stringify(values)
            };
    return fetch(`${process.env.REACT_APP_API_URL}api/auth/users/value`, requestOptions).then(handleResponse).then(data => {
            let existing = JSON.parse(localStorage.getItem('user'));
            let newData = {
                "status": existing.status,
                "message": existing.message,
                data: {
                    "firstName": existing.data.firstName,
                    "lastName": existing.data.lastName,
                    "token": existing.data.token,
                    "values": data.data,
                    "purpose": existing.data.purpose,
                    "interests": existing.data.interests,
                    "email": existing.data.email,
                    "avatar": existing.data.avatar,
                }
            }
            localStorage.setItem('user', JSON.stringify(newData));
            return data;
        });
}

function updateInterest(interests) {
    const requestOptions = {
                method: 'PUT',
                headers: { ...authHeader(), 'Content-Type': 'application/json' },
                body: JSON.stringify(interests)
            };
    return fetch(`${process.env.REACT_APP_API_URL}api/auth/users/interest`, requestOptions).then(handleResponse).then(data => {
            let existing = JSON.parse(localStorage.getItem('user'));
            let newData = {
                "status": existing.status,
                "message": existing.message,
                data: {
                    "firstName": existing.data.firstName,
                    "lastName": existing.data.lastName,
                    "token": existing.data.token,
                    "values": existing.data.values,
                    "purpose": existing.data.purpose,
                    "interests": data.data,
                    "email": existing.data.email,
                    "avatar": existing.data.avatar,
                }
            }
            localStorage.setItem('user', JSON.stringify(newData));
            return data;
        });
}

function facebookLogin(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

     return fetch(`${process.env.REACT_APP_API_URL}api/auth/users/facebookLogin`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
}

function googleLogin(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

     return fetch(`${process.env.REACT_APP_API_URL}api/auth/users/googleLogin`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
}

function linkedinLogin() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
     return fetch(`${process.env.REACT_APP_API_URL}api/auth/users/linkedinLogin`, requestOptions)
        .then(handleResponse)
        .then(url => {
           window.location = url.data;
        });
}

function linkedinback(values) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values)
    };

     return fetch(`${process.env.REACT_APP_API_URL}api/auth/users/linkedinBack`, requestOptions)
       .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
}

function getAll() {

    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/auth/users/allUsers`, requestOptions).then(handleResponse);
}

function getUserRole(param) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(param)
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/auth/users/userRole`, requestOptions).then(handleResponse);
}

function deleteUser(param) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(param)
    };

    console.log( {param} );
    return fetch(`${process.env.REACT_APP_API_URL}api/auth/users/deleteUser`, requestOptions).then(handleResponse);
}

function updateRoleService(param) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(param)
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/auth/users/updateUser`, requestOptions).then(handleResponse);
}
