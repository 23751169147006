export const goalConstants = {
    GETALL_CATEGORY_REQUEST: 'CATEGORY_GETALL_REQUEST',
    GETALL_CATEGORY_SUCCESS: 'CATEGORY_GETALL_SUCCESS',
    GETALL_CATEGORY_FAILURE: 'CATEGORY_GETALL_FAILURE',

    SAVE_REQUEST: 'GOAL_SAVE_REQUEST',
    SAVE_SUCCESS: 'GOAL_SAVE_SUCCESS',
    SAVE_FAILURE: 'GOAL_SAVE_FAILURE',

    GETALL_REQUEST: 'GOALS_GETALL_REQUEST',
    GETALL_SUCCESS: 'GOALS_GETALL_SUCCESS',
    GETALL_FAILURE: 'GOALS_GETALL_FAILURE',

    GETDETAILS_REQUEST: 'GOALS_GETDETAILS_REQUEST',
    GETDETAILS_SUCCESS: 'GOALS_GETDETAILS_SUCCESS',
    GETDETAILS_FAILURE: 'GOALS_GETDETAILS_FAILURE',

    GET_STEPS_REQUEST: 'GOALS_GETSTEPS_REQUEST',
    GET_STEPS_SUCCESS: 'GOALS_GETSTEPS_SUCCESS',
    GET_STEPS_FAILURE: 'GOALS_GETSTEPS_FAILURE',

    UPDATE_STEPS_REQUEST: 'GOALS_UPDATESTEP_REQUEST',
    UPDATE_STEP_SUCCESS: 'GOALS_UPDATESTEP_SUCCESS',
    UPDATE_STEP_FAILURE: 'GOALS_UPDATESTEP_FAILURE',

    LOAD_MORE_REQUEST: 'GOALS_LOAD_MORE_REQUEST',
    LOAD_MORE_SUCCESS: 'GOALS_LOAD_MORE_SUCCESS',
    LOAD_MORE_FAILURE: 'GOALS_LOAD_MORE_FAILURE',

    DELETE_GOAL_REQUEST: 'GOALS_DELETE_REQUEST',
    DELETE_GOAL_SUCCESS: 'GOALS_DELETE_SUCCESS',
    DELETE_GOAL_FAILURE: 'GOALS_DELETE_FAILURE',

    UPDATE_REQUEST: 'GOAL_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'GOAL_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'GOAL_UPDATE_FAILURE',
};