export const journalConstants = {
    GETALL_REQUEST: 'JOURNALS_GETALL_REQUEST',
    GETALL_SUCCESS: 'JOURNALS_GETALL_SUCCESS',
    GETALL_FAILURE: 'JOURNALS_GETALL_FAILURE',

    SAVE_REQUEST: 'JOURNALS_SAVE_REQUEST',
    SAVE_SUCCESS: 'JOURNALS_SAVE_SUCCESS',
    SAVE_FAILURE: 'JOURNALS_SAVE_FAILURE',

    LOAD_MORE_REQUEST: 'JOURNALS_LOAD_MORE_REQUEST',
    LOAD_MORE_SUCCESS: 'JOURNALS_LOAD_MORE_SUCCESS',
    LOAD_MORE_FAILURE: 'JOURNALS_LOAD_MORE_FAILURE',

    DELETE_REQUEST: 'JOURNALS_DELETE_REQUEST',
    DELETE_SUCCESS: 'JOURNALS_DELETE_SUCCESS',
    DELETE_FAILURE: 'JOURNALS_DELETE_FAILURE',
};