import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Newsletter from "./Newsletter";
import Header from "../Pages/Header";
import Sidebar from "../Pages/Sidebar";

import { connect } from "react-redux";
import { contentActions } from "../../actions";
import * as moment from "moment";

class ContentsPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
      		disabled: false,
    	};
		this.row = 0;
		this.rowperpage = 3;
		this.buttonText = "Load More";
		this.loadMore = this.loadMore.bind(this);
	}
	componentDidMount() {
		this.props.getAllWithoutLimit();
		this.props.getContents(this.row);
	}

	loadMore(e) {
		e.preventDefault();
		this.row += this.rowperpage;
		this.props.loadMore(this.row);
		this.buttonText = "Load More";
		if (this.row >= this.props.contents.wpTotal) {
			this.setState({ disabled: true });
		}
  	}

	get getButtonText() {
		if (this.props.contents.loading) return 'Loading...';
		if (this.props.contents.error) return 'Error has occurred :(';
		return 'Load more'
	} 

	render() {
		const { contents } = this.props;
		return (
			<div className="page-wrap-main">
				<Header />

				<div className="wrapper-main">
					<Sidebar />

					<div className="page-content">
						<div className="card--white full-height contents-view">
							<h3 className="cust-heading">Content</h3>
							<span className="subheading">
								
							</span>

							<Row className="content-posts-block">
								{contents.allContentWithoutLimit &&
									contents.allContentWithoutLimit.map((content, index) => {
										if (content.acf.is_featured[0]=="Yes") {
											const desc = content.acf.content_section_1.replace(
												/<[^>]+>/g,
												""
											);
											return (
												<Col md={6} className="content-posts-cell" key={index}>
													<Link
														className="content-cell"
														to={"/contents/contents-detail/"+content.id}
													>
														<div className="img-view-box">
															<img src={content.acf.post_image} alt="" />
															<span className="cat-label">{ content.post_categories[0].name }</span>
														</div>
														<h2>{content.acf.title}</h2>
														<p>{desc.substring(0, 100)}</p>
														<span className="author">
															By {content.acf.author_details.name} &#x02223;{" "}
															<span>
																{moment(content.acf.date, "DD/MM/YYYY").format(
																	"MMMM DD, YYYY"
																)}
															</span>
														</span>
													</Link>
												</Col>
											);
										}
									})}
							</Row>

							<Newsletter />

							<Row className="content-posts-block content-posts-sm">
								{contents.items &&
									contents.items.map((content, index) => {
										const desc = content.acf.content_section_1.replace(
											/<[^>]+>/g,
											""
										);
										return (
											<Col xl={4} lg={6} className="content-posts-cell" key={index}>
												<Link
													className="content-cell faded-cell"
													to={"/contents/contents-detail/"+content.id}
												>
													<div className="img-view-box">
														<img src={content.acf.post_image} alt="" />
														<span className="cat-label">{ content.post_categories[0].name }</span>
													</div>
													<h2>{content.acf.title}</h2>
													<p>{desc.substring(0, 100)}</p>
												</Link>
											</Col>
										);
									})}
							</Row>

							<div className="link-btn-wrap text-center">
								{ this.row < contents.wpTotal  && contents.wpTotal > 3 &&
									<button className="theme-dark-outline__btn load-more-btn" onClick={this.loadMore} disabled={this.state.disabled}>
									{this.getButtonText}
									</button>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapState(state) {
	const { contents } = state;
	return { contents };
}

const actionCreators = {
	getContents: contentActions.getAll,
	getPostCategories: contentActions.getPostCategories,
	loadMore: contentActions.loadMore,
	getAllWithoutLimit: contentActions.getAllWithoutLimit,
};

export default connect(mapState, actionCreators)(ContentsPage);
