import React from 'react';
import { Button, Form, Modal } from "react-bootstrap";

import { connect } from 'react-redux';
import { valueActions, userActions } from '../../../actions';

class AddValues extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			userValues: [],
			custom: '',
		}
		this.hanldeSubmit = this.hanldeSubmit.bind(this);
	}

	componentDidMount() {
		this.props.getall();
		this.setState({userValues: this.props.user.data.values});
	}

	hanldeSubmit() {
		if (this.state.custom || this.state.userValues) {
			if (this.state.custom) {
				var values = {
					"values": this.state.userValues,
					"custom": this.state.custom,
				}
				this.props.updatevalue(values);
				this.setState({...this.state, custom : ''});
			}
			else {
				var values = {
					"values": this.state.userValues,
				}
				this.props.updatevalue(values);
			}
		}
		this.props.onHide();
	}
	onChangeValues = (e) => {
		// check if the check box is checked or unchecked
		if (e.target.checked) {
			this.state.userValues.push({
				_id: e.target.id, 
				title:  e.target.value
        	});
		} else {
			 this.setState({
        		userValues : this.state.userValues.filter(function(obj) {return obj._id !== e.target.id;})
      		})
		}
	}
	handleChangeCustom = (event) => {
		const { name, value } = event.target;
		 this.setState({ 
			...this.state,
            [name]: value
        });
		/* this.setState({userValues: this.props.user.data.values}); */
	}
	submitSettings = (value) => {
		this.setState({
			...this.state,
        	userValues : this.state.userValues.filter(function(obj) {return obj._id != value._id}),
      	});
		//this.setState({userValues: this.props.user.data.values});
	}

	render() {
		const { values, user } = this.props;
		return (
			<Modal
				/* {...this.props} */
				show={this.props.show} onHide={this.props.onHide}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered id="addvalues-modal" className="cust-modal multiselectcheck-modal addvalues-modal">
				<Modal.Header closeButton>
					<h3 className="cust-heading-center">Add values</h3>
				</Modal.Header>

				<div className="multiselectcheck-modal-body">
						{values.items &&
							<Form className="multiselectcheck-modal-Form">
								{values.items.map((value, index) =>
									<div key={value._id} className="custom-checkbox">
										{['checkbox'].map((type) => (
											<div key={`custom-${type}`}>
												<Form.Check
													custom
													type={type}
													id={`${value._id}`}
													label={`${value.title}`}
													value={`${value.title}`}
													defaultChecked={user.data.values.some(function(userValue){ return userValue._id === value._id})}
													onClick={this.onChangeValues}
												/>
											</div>
										))}
									</div>
								)}
								<Form.Group controlId="custom" className="custom-field-add">
									<Form.Control type="text" placeholder="+ Custom" name="custom" onChange={this.handleChangeCustom} autoComplete="off" />
								</Form.Group>
							</Form>
                		}
				</div>

				<Modal.Footer className="justify-content-center">
				  <Button onClick={this.props.onHide} className="theme-dark-outline__btn cust-cancel-btn">Cancel</Button>
				  <Button className="theme-dark__btn cust-save-btn" onClick={this.hanldeSubmit}>Save</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

function mapState(state) {
    const { values, user } = state;
    return { values, user };
}

const actionCreators = {
    getall: valueActions.getAll,
    save: valueActions.save,
	updatevalue: userActions.updateValue,
}

export default connect(mapState, actionCreators, null, {forwardRef:true})(AddValues);