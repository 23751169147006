import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import parse from 'html-react-parser';
import { connect } from "react-redux";
import { journalActions } from "../../actions";
import * as moment from "moment";
import ShowMoreText from "react-show-more-text";
import Header from "../Pages/Header";
import Sidebar from "../Pages/Sidebar";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
var he = require('he');

class JournalLog extends React.Component {
  componentDidMount() {
    this.props.getAll();
  }
  constructor(props) {
      super(props);
        this.state = {
        disabled: false,
      };
      this.row = 0;
      this.rowperpage = 5;
      this.buttonText = "Load More";
      this.loadMore = this.loadMore.bind(this);
  }

  loadMore(e) {
    e.preventDefault();
    this.row += this.rowperpage;
    this.props.loadMore(this.row);
    this.buttonText = "Load More";
    if (this.row >= this.props.journals.totalNumberOfRows) {
         this.setState({ disabled: true });
    }
  }

   get getButtonText() {
    if (this.props.journals.loading) return 'Loading...';
    if (this.props.journals.error) return 'Error has occurred :(';
    return 'Load more'
  } 

  submit = (e) => {
    let id = e.target.getAttribute('id');
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete this journal.',
      buttons: [
        {
          label: 'Yes',
          className: 'theme-dark-outline__btn cust-cancel-btn btn btn-primary',
          onClick: () => this.deleteJournal(id)
        },
        {
          label: 'No',
          className: 'theme-dark__btn cust-save-btn btn btn-primary'
        }
      ]
    });
  };

  deleteJournal = (id) => {
    this.props.deleteJournal(id);
  }

  render() {
    const { journals } = this.props;
    const allJournals = journals.items ? journals.items : [];
    return (
      <div className="page-wrap-main">
        <Header />

        <div className="wrapper-main">
          <Sidebar />

          <div className="page-content">
            <div className="card--white full-height journal-log-view">
              <div className="cust-head-center-wrap">
                <Button
                  className="back-arrow"
                  onClick={() => this.props.history.goBack()}
                ></Button>

                <h2 className="cust-heading-center">journal log</h2>
              </div>

              { allJournals.length > 0 ? 
                (<div className="expand-log-details">
                  {journals.items &&
                    journals.items.map((journal, index) => (
                      <Row className="expand-row" key={journal._id}>
                        <Col md={10} className="expand-log-left">
                          <ShowMoreText
                            lines={1}
                            more="Read More +"
                            less="Read less -"
                            className="expand-log-description"
                            anchorClass="read-more-show"
                            expanded={false}
                            width={700}
                          >
                            <p className="expand-log-heading">{journal.title ? he.decode(journal.title) : ""}</p>
                            <p className="read-more-content" >
                              
			    { journal.description ? parse(journal.description) : ""   }
                            </p>
                          </ShowMoreText>
                        </Col>
                        <Col md={2} className="journal-right">
                          <span className="expand-log-date">
                            {moment(journal.createdAt).format("MMMM DD, YYYY")}
                          </span>
                          <button className="ml-2 delete-ico" id={journal._id} onClick={this.submit}></button>
                        </Col>
                      </Row>
                    ))}
                </div>)  : (<span className="no-data-found text-center">You have not added your journals yet.</span>)
              }

              <div className="link-btn-wrap text-center">
                { this.row < journals.totalNumberOfRows  && journals.totalNumberOfRows > 5 &&
                    <button className="theme-dark-outline__btn load-more-btn" onClick={this.loadMore} disabled={this.state.disabled}>
                      {this.getButtonText}
                    </button>
                  }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { journals } = state;
  return { journals };
}

const actionCreators = {
  getAll: journalActions.getAll,
  loadMore: journalActions.loadMore,
  deleteJournal: journalActions.deleteJournal,
};

export default connect(mapState, actionCreators)(JournalLog);
