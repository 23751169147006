import React from 'react';
import { Button, Modal} from "react-bootstrap";
import $ from "jquery";

import { connect } from 'react-redux';
import { userActions } from '../../../actions';

class ProfileUpload extends React.Component {
	componentDidMount() {
		$(document).on("click",function(){
			$('.cust-upload input').on("change", function () {
				$('.cust-upload p').text(this.files.length + " file(s) selected");
			});
		});
	}
	constructor(props){
        super(props);
        this.state={
            uploadedFile:null
        }
		this.hanldeSubmit = this.hanldeSubmit.bind(this);
    }
	changeProfileImage=(event)=>{
        this.setState({uploadedFile:event.target.files[0]});
    }
	// handle button click event and pass data in parent
	hanldeSubmit() {
		/* this.props.handleprofilevalue(this.state.uploadedFile); */
		//create object of form data
		const formData=new FormData();
		if (this.state.uploadedFile) {
			formData.append("avatar",this.state.uploadedFile);
		}
		this.props.updateAvatar(formData);
		/* if (this.props.user.status) { */
			this.props.onHide();
		/* } */
	}
	render() {
		return (
			<Modal
				{...this.props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered className="cust-modal ProfileUpload-modal">
				<Modal.Header closeButton>
					<h3 className="cust-heading-center">Profile picture</h3>
				</Modal.Header>
				<form className="cust-upload" enctype="multipart/form-data">
					<input type="file" onChange={this.changeProfileImage} accept="image/x-png,image/jpeg" />
					{/* <TextValidator
						onChange={this.changeProfileImage}
						type="file"
						validators={['isFile', 'maxFileSize:' + 1 * 1024 * 1024, 'allowedExtensions:image/png,image/jpeg']}
						errorMessages={['File is not valid', 'Size must not exceed 1MB', 'Only png and jpeg']}
            		/> */}
					<p>Drop your image or <strong>upload from computer</strong></p>
				</form>
				<Modal.Footer className="justify-content-center">
				  <Button onClick={this.props.onHide} className="theme-dark-outline__btn cust-cancel-btn">Cancel</Button>
				  <Button className="theme-dark__btn cust-save-btn" type="button" onClick={this.hanldeSubmit}>Save</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

function mapState(state) {
    const { authentication } = state;
	const { user } = authentication;
    return { user };
}

const actionCreators = {
    updateAvatar: userActions.updateAvatar,
}

export default connect(mapState, actionCreators)(ProfileUpload);