export const userfeelConstants = {
    GETALL_REQUEST: 'USER_FEELS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USER_FEELS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USER_FEELS_GETALL_FAILURE',

    SAVE_REQUEST: 'USER_FEELS_SAVE_REQUEST',
    SAVE_SUCCESS: 'USER_FEELS_SAVE_SUCCESS',
    SAVE_FAILURE: 'USER_FEELS_SAVE_FAILURE',

    LOAD_MORE_REQUEST: 'USER_FEELS_LOAD_MORE_REQUEST',
    LOAD_MORE_SUCCESS: 'USER_FEELS_LOAD_MORE_SUCCESS',
    LOAD_MORE_FAILURE: 'USER_FEELS_LOAD_MORE_FAILURE',

    DELETE_REQUEST: 'USER_FEELS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USER_FEELS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USER_FEELS_DELETE_FAILURE',
};