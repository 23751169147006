import { userConstants } from '../constants';

const initialState = { 
  loggingIn: false,
  loggedIn: false
}

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        loggedIn: false
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        loggingIn: false
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loggingIn: false,
        loggedIn: false
      };
    case userConstants.FACEBOOK_LOGIN_REQUEST:
      return {
        loggingIn: true,
        loggedIn: false
      };
    case userConstants.FACEBOOK_LOGIN_SUCCESS:
      return {
        loggedIn: true,
        loggingIn: false
      };
    case userConstants.FACEBOOK_LOGIN_FAILURE:
      return {
        loggingIn: false,
        loggedIn: false
      };
    case userConstants.GOOGLE_LOGIN_REQUEST:
      return {
        loggingIn: true,
        loggedIn: false
      };
    case userConstants.GOOGLE_LOGIN_SUCCESS:
      return {
        loggedIn: true,
        loggingIn: false
      };
    case userConstants.GOOGLE_LOGIN_FAILURE:
      return {
        loggingIn: false,
        loggedIn: false
      };
    case userConstants.LINKEDIN_LOGIN_REQUEST:
      return {
        loggingIn: true,
        loggedIn: false
      };
    case userConstants.LINKEDIN_LOGIN_SUCCESS:
      return {
        loggedIn: true,
        loggingIn: false
      };
    case userConstants.LINKEDIN_LOGIN_FAILURE:
      return {
        loggingIn: false,
        loggedIn: false
      };
    case userConstants.LOGOUT:
      return initialState;
    default:
      return state
  }
}