export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    GETUSER_REQUEST: 'USER_GET_REQUEST',
    GETUSER_SUCCESS: 'USER_GET_SUCCESS',
    GETUSER_FAILURE: 'USER_GET_FAILURE',

    FORGOT_PASSWORD_REQUEST: 'USERS_FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS: 'USERS_FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'USERS_FORGOT_PASSWORD_FAILURE',

    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

    UPDATE_AVATAR_REQUEST: 'USERS_UPDATE_AVATAR_REQUEST',
    UPDATE_AVATAR_SUCCESS: 'USERS_UPDATE_AVATAR_SUCCESS',
    UPDATE_AVATAR_FAILURE: 'USERS_UPDATE_AVATAR_FAILURE',

    UPDATE_VALUE_REQUEST: 'USERS_UPDATE_VALUE_REQUEST',
    UPDATE_VALUE_SUCCESS: 'USERS_UPDATE_VALUE_SUCCESS',
    UPDATE_VALUE_FAILURE: 'USERS_UPDATE_VALUE_FAILURE',

    DELETE_VALUE_REQUEST: 'USERS_DELETE_VALUE_REQUEST',
    DELETE_VALUE_SUCCESS: 'USERS_DELETE_VALUE_SUCCESS',
    DELETE_VALUE_FAILURE: 'USERS_DELETE_VALUE_FAILURE',

    DELETE_INTEREST_REQUEST: 'USERS_INTEREST_DELETE_REQUEST',
    DELETE_INTEREST_SUCCESS: 'USERS_INTEREST_DELETE_SUCCESS',
    DELETE_INTEREST_FAILURE: 'USERS_INTEREST_DELETE_FAILURE',

    UPDATE_INTEREST_REQUEST: 'USERS_UPDATE_INTEREST_REQUEST',
    UPDATE_INTEREST_SUCCESS: 'USERS_UPDATE_INTEREST_SUCCESS',
    UPDATE_INTEREST_FAILURE: 'USERS_UPDATE_INTEREST_FAILURE',

    FACEBOOK_LOGIN_REQUEST: 'USERS_FACEBOOK_LOGIN_REQUEST',
    FACEBOOK_LOGIN_SUCCESS: 'USERS_FACEBOOK_LOGIN_SUCCESS',
    FACEBOOK_LOGIN_FAILURE: 'USERS_FACEBOOK_LOGIN_FAILURE',

    GOOGLE_LOGIN_REQUEST: 'USERS_GOOGLE_LOGIN_REQUEST',
    GOOGLE_LOGIN_SUCCESS: 'USERS_GOOGLE_LOGIN_SUCCESS',
    GOOGLE_LOGIN_FAILURE: 'USERS_GOOGLE_LOGIN_FAILURE',

    LINKEDIN_LOGIN_REQUEST: 'USERS_LINKEDIN_LOGIN_REQUEST',
    LINKEDIN_LOGIN_SUCCESS: 'USERS_LINKEDIN_LOGIN_SUCCESS',
    LINKEDIN_LOGIN_FAILURE: 'USERS_LINKEDIN_LOGIN_FAILURE',
};

export default userConstants;
