const initialState =  {
    showAddUserModal: 0
}

const modalReduces = (state = initialState,action ) => {
    switch (action.type) {
        case 'addNewUserModal':
            return{
                ...state,
                showAddUserModal:action.payload
            }
        default:
            return state;
    }
}

export {
    modalReduces
}