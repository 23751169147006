export const contentConstants = {
    GETALL_REQUEST: 'CONTENTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'CONTENTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'CONTENTS_GETALL_FAILURE',

    GETALL_DETAILS_REQUEST: 'CONTENTS_GETDETAILS_REQUEST',
    GETALL_DETAILS_SUCCESS: 'CONTENTS_GETDETAILS_SUCCESS',
    GETALL_DETAILS_FAILURE: 'CONTENTS_GETDETAILS_FAILURE',

    GET_CATEGORIES_REQUEST: 'CONTENTS_GETCATEGORIES_REQUEST',
    GET_CATEGORIES_SUCCESS: 'CONTENTS_GETCATEGORIES_SUCCESS',
    GET_CATEGORIES_FAILURE: 'CONTENTS_GETCATEGORIES_FAILURE',

    GETALL_RELATED_REQUEST: 'CONTENTS_GETALL_RELATED_REQUEST',
    GETALL_RELATED_SUCCESS: 'CONTENTS_GETALL_RELATED_SUCCESS',
    GETALL_RELATED_FAILURE: 'CONTENTS_GETALL_RELATED_FAILURE',

    LOAD_MORE_REQUEST: 'CONTENTS_LOAD_MORE_REQUEST',
    LOAD_MORE_SUCCESS: 'CONTENTS_LOAD_MORE_SUCCESS',
    LOAD_MORE_FAILURE: 'CONTENTS_LOAD_MORE_FAILURE',

    GETALL_WITHOUT_LIMIT_REQUEST: 'CONTENTS_GETALL_WITHOUT_LIMIT_REQUEST',
    GETALL_WITHOUT_LIMIT_SUCCESS: 'CONTENTS_GETALL_WITHOUT_LIMIT_SUCCESS',
    GETALL_WITHOUT_LIMIT_FAILURE: 'CONTENTS_GETALL_WITHOUT_LIMIT_FAILURE',
};