import { contentConstants } from '../constants';

export function contents(state = {}, action) {
  switch (action.type) {
    case contentConstants.GETALL_REQUEST:
      return {
         ...state,
        loading: true
      };
    case contentConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        items: action.contents.res.data,
        wpTotal: action.contents.wpTotal
      };
    case contentConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };
    case contentConstants.GETALL_DETAILS_REQUEST:
      return {
         ...state,
        loading: true
      };
    case contentConstants.GETALL_DETAILS_SUCCESS:
      return {
         ...state,
        loading: false,
        error: null,
        item: action.contents.data
      };
    case contentConstants.GETALL_DETAILS_FAILURE:
      return { 
        error: action.error
      };
    case contentConstants.GET_CATEGORIES_REQUEST:
      return {
         ...state,
        loading: true
      };
    case contentConstants.GET_CATEGORIES_SUCCESS:
      return {
        loading: false,
        error: null,
        item: action.contents
      };
    case contentConstants.GET_CATEGORIES_FAILURE:
      return { 
        error: action.error
      };
    case contentConstants.GETALL_RELATED_REQUEST:
      return {
         ...state,
        loading: true
      };
    case contentConstants.GETALL_RELATED_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        related: action.contents.data
      };
    case contentConstants.GETALL_RELATED_FAILURE:
      return { 
        error: action.error
      };
    case contentConstants.LOAD_MORE_REQUEST:
      return {
         ...state,
        loading: true
      };
    case contentConstants.LOAD_MORE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: [ ...(state.items || []), ...action.contents.res.data ]
      };
      case contentConstants.LOAD_MORE_FAILURE:
        return { 
          error: action.error
        };
      case contentConstants.GETALL_WITHOUT_LIMIT_REQUEST:
      return {
         ...state,
        loading: false,
      };
    case contentConstants.GETALL_WITHOUT_LIMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allContentWithoutLimit: action.contents.data,
      };
    case contentConstants.GETALL_WITHOUT_LIMIT_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}