import { userConstants } from '../constants';
import { userService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const userActions = {
    login,
    logout,
    register,
    forgotPassword,
    get,
    delete: _delete,
    update,
    updateAvatar,
    deleteValue,
    deleteInterest,
    updateValue,
    updateInterest,
    facebookLogin,
    googleLogin,
    linkedinLogin,
    linkedinback,
    getAll
};

function login(email, password) {
    return dispatch => {
        dispatch(request({ email }));

        userService.login(email, password)
            .then(
                user => { 
                    dispatch(success(user));
                    console.log('fffff', user );
                    if( user?.data?.role === '1' ){
                        history.push('/users');
                        toast.success(user.message); 
                    }else if( user?.data?.role === '2' ){
                        history.push('/dashboard');
                        toast.success(user.message); 
                    }else{
                        history.push('/register');
                        localStorage.clear();
                        let error = 'User is blocked or role not defined';
                        toast.success(error); 
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    toast.error(error.toString());
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

async function logout() {
    await userService.logout();
    history.push('/register');
    window.location.reload();
    return { type: userConstants.LOGOUT };
}

function register(user) {
    return dispatch => {
        dispatch(request(user));
        userService.register(user)
        .then(
            user => { 
                    dispatch(success());
                    dispatch(alertActions.success(user.message));
                    toast.success(user.message); 
                   /*  document.getElementById("signup-splash-view-btn").click();
                    document.getElementById("sign-form").reset(); */
                    //history.push('/register');
                    /* setTimeout(function(){
                        window.location.reload(true);
                    }, 2000); */
                },
                error => {
                    dispatch(failure(error.toString()));
                   /*  dispatch(alertActions.error(error.toString())); */
                    toast.error(error.toString()); 
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function get() {
    return dispatch => {
        dispatch(request());

        userService.get()
            .then(
                user => dispatch(success(user)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETUSER_REQUEST } }
    function success(user) { return { type: userConstants.GETUSER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GETUSER_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}

function forgotPassword(user) {
    return dispatch => {
        dispatch(request(user));

        userService.forgotPassword(user)
            .then(
                user => { 
                    dispatch(success());
                    history.push('/register');
                    dispatch(alertActions.success('Password sent on your email please check your email and login with new password.'));
                    toast.success(user.message); 
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    toast.error(error.toString());
                }
            );
    };

    function request(user) { return { type: userConstants.FORGOT_PASSWORD_REQUEST, user } }
    function success(user) { return { type: userConstants.FORGOT_PASSWORD_SUCCESS, user } }
    function failure(user, error) { return { type: userConstants.FORGOT_PASSWORD_FAILURE, user, error } }
}

function update(data) {
    return dispatch => {
        dispatch(request(data));
        userService.update(data)
            .then(
                user => { 
                    dispatch(success(user));
                    history.push('/profile');
                    //toast.success(user.message); 
                },
                error => {
                    dispatch(failure(error.toString()));
                   // dispatch(alertActions.error(error.toString()));
                    toast.error(error.toString()); 
                }
            );
    };
    function request(user) { return { type: userConstants.UPDATE_REQUEST, user } }
    function success(user) { return { type: userConstants.UPDATE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
}

function updateAvatar(formData) {
    return dispatch => {
        dispatch(request(formData));
        userService.updateAvatar(formData)
            .then(
                data => { 
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    toast.error(error.toString()); 
                }
            );
    };
    function request(data) { return { type: userConstants.UPDATE_AVATAR_REQUEST, data } }
    function success(data) { return { type: userConstants.UPDATE_AVATAR_SUCCESS, data } }
    function failure(error) { return { type: userConstants.UPDATE_AVATAR_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function deleteValue(id) {
    return dispatch => {
        dispatch(request(id));

        userService.deleteValue(id)
            .then(
                user => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: userConstants.DELETE_VALUE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_VALUE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_VALUE_FAILURE, id, error } }
}

function deleteInterest(id) {
    return dispatch => {
        dispatch(request(id));

        userService.deleteInterest(id)
            .then(
                user => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: userConstants.DELETE_INTEREST_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_INTEREST_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_INTEREST_FAILURE, id, error } }
}

function updateValue(formData) {
    return dispatch => {
        dispatch(request(formData));
        userService.updateValue(formData)
            .then(
                data => { 
                    dispatch(success(data.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    toast.error(error.toString()); 
                }
            );
    };
    function request(data) { return { type: userConstants.UPDATE_VALUE_REQUEST, data } }
    function success(data) { return { type: userConstants.UPDATE_VALUE_SUCCESS, data } }
    function failure(error) { return { type: userConstants.UPDATE_VALUE_FAILURE, error } }
}

function updateInterest(formData) {
    return dispatch => {
        dispatch(request(formData));
        userService.updateInterest(formData)
            .then(
                data => { 
                    dispatch(success(data.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    toast.error(error.toString()); 
                }
            );
    };
    function request(data) { return { type: userConstants.UPDATE_INTEREST_REQUEST, data } }
    function success(data) { return { type: userConstants.UPDATE_INTEREST_SUCCESS, data } }
    function failure(error) { return { type: userConstants.UPDATE_INTEREST_FAILURE, error } }
}

function facebookLogin(user) {
    return dispatch => {
        dispatch(request(user));

        userService.facebookLogin(user)
            .then(
                user => { 
                   dispatch(success(user));
                    history.push('/dashboard');
                    toast.success(user.message); 
                },
                error => {
                    dispatch(failure(error.toString()));
                    toast.error(error.toString()); 
                }
            );
    };

    function request(user) { return { type: userConstants.FACEBOOK_LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.FACEBOOK_LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.FACEBOOK_LOGIN_FAILURE, error } }
}

function googleLogin(user) {
    return dispatch => {
        dispatch(request(user));

        userService.googleLogin(user)
            .then(
                user => { 
                   dispatch(success(user));
                    history.push('/dashboard');
                    toast.success(user.message); 
                },
                error => {
                    dispatch(failure(error.toString()));
                    toast.error(error.toString()); 
                }
            );
    };

    function request(user) { return { type: userConstants.GOOGLE_LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.GOOGLE_LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GOOGLE_LOGIN_FAILURE, error } }
}

function linkedinLogin() {
    userService.linkedinLogin();
}

function linkedinback(user) {
    return dispatch => {
        dispatch(request(user));
        userService.linkedinback(user)
            .then(
                user => { 
                   dispatch(success(user));
                    history.push('/dashboard');
                    toast.success(user.message); 
                },
                error => {
                    dispatch(failure(error.toString()));
                    toast.error(error.toString()); 
                }
            );
    };

    function request(user) { return { type: userConstants.LINKEDIN_LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LINKEDIN_LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LINKEDIN_LOGIN_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request());

        
        userService.getAll()
            .then(
                user => { console.log('jjjj', user ); return dispatch(success(user)); }, 
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETUSER_REQUEST } }
    function success(user) { return { type: userConstants.GETUSER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GETUSER_FAILURE, error } }
}