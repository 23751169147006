import React from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import $ from "jquery";

class SelectJournalPrompt extends React.Component {
	componentDidMount() {
		$(document).on("click", ".counter-step-one-next", function () {
			$(".counter-step-two").show();
			$(".counter-step-one").hide();

		});

		$(document).on("click", ".counter-step-two-back", function () {
			$(".counter-step-one").show();
			$(".counter-step-two").hide();
		});

		$(document).on("click", ".edit-ico", function () {
			$(".edit-title").removeAttr("readOnly");
		});
	}

	constructor() {
		super();
		this.state = {
			CheckboxData: [{
				id: 1,
				value: 1,
				labelText: 'What’s on your mind?'
			},
			{
				id: 2,
				value: 2,
				labelText: 'What did you achieve today?'
			},
			{
				id: 3,
				value: 3,
				labelText: 'Describe how you’re feeling both physically and emotionally'
			},
			{
				id: 4,
				value: 4,
				labelText: 'What brings you joy?'
			},
			{
				id: 5,
				value: 5,
				labelText: 'What does your dream life look like?'
			
			},
			{
				id: 6,
				value: 6,
				labelText: 'What is one thing that you’d like to be different by this time next year?'
			
			},
			{
				id: 7,
				value: 7,
				labelText: 'List five traits that you love about yourself?'
			
			},
			{
				id: 8,
				value: 8,
				labelText: 'How are you currently sabotaging your goals?'
			},
			{
				id: 9,
				value: 9,
				labelText: 'What are you interested in learning more about?'
			},
			{
				id: 10,
				value: 10,
				labelText: 'List five habits that you want to include in your daily routine'
			},
			{
				id: 12,
				value: 12,
				labelText: 'What is bothering you right now?'
			},
			{
				id: 13,
				value: 13,
				labelText: 'What are some challenges that you are currently facing and how can you overcome them?'
			},
			{
				id: 14,
				value: 14,
				labelText: 'Name three people that you are blessed to have in your life?'
			},
			{
				id: 15,
				value: 15,
				labelText: 'What are five things that you are really good at?'
			},
			{
				id: 16,
				value: 16,
				labelText: 'What do I need to do more of this year?'
			},
			{
				id: 17,
				value: 17,
				labelText: 'What are your five biggest fears?'
			},
			{
				id: 18,
				value: 18,
				labelText: 'What are you putting off right now?'
			},
			{
				id: 19,
				value: 19,
				labelText: 'What did you learn today?'
			},
			{
				id: 20,
				value: 20,
				labelText: 'How can you do better tomorrow?'
			},
			{
				id: 21,
				value: 21,
				labelText: 'Who or what gives you comfort?'
			},
			{
				id: 22,
				value: 22,
				labelText: 'What do you feel like your life is missing and how can you get more of what you need?'
			},
			{
				id: 23,
				value: 23,
				labelText: 'How can you love yourself more daily?'
			},
			{
				id: 24,
				value: 24,
				labelText: 'What is the best compliment you’ve ever been given? How did it make you feel?'
			},
			{
				id: 25,
				value: 25,
				labelText: 'Write a thank-you note to yourself'
			},
			{
				id: 26,
				value: 26,
				labelText: 'If failure was impossible, what would you try that you have never done before?'
			},
			{
				id: 27,
				value: 27,
				labelText: 'What is one thing that you need to start saying yes to and why?'
			},
			{
				id: 28,
				value: 28,
				labelText: 'When was the last time you did something for the first time? What was it? How did it make you feel?'
			},
			{
				id: 29,
				value: 29,
				labelText: 'What would you tell your past self?'
			},
			{
				id: 30,
				value: 30,
				labelText: 'What are you in control of at this very moment?'
			},
			{
				id: 31,
				value: 31,
				labelText: 'How do you refresh?'
			},
			{
				id: 32,
				value: 32,
				labelText: 'What is one goal that you can accomplish in the week ahead?'
			},
			{
				id: 33,
				value: 33,
				labelText: 'Describe your ideal day'
			},
			{
				id: 34,
				value: 34,
				labelText: 'Who is inspiring you right now?Why?'
			},
			{
				id: 35,
				value: 35,
				labelText: 'Who can you encourage today? How?'
			},
			{
				id: 36,
				value: 36,
				labelText: 'What habits would you like to change/What scares you?'
			},
			{
				id: 37,
				value: 37,
				labelText: 'What did you learn this week?'
			},
			{
				id: 38,
				value: 38,
				labelText: 'What does self-care look like for you?'
			},
			{
				id: 39,
				value: 39,
				labelText: 'What did you to make yourself proud this week?'
			}],
			selectedOption: 0,
			selectedOptionText: '',
		}
	}

	changeHandler = (e) => {
		this.setState({
    		selectedOption: e.target.value,
			selectedOptionText: e.target.getAttribute("data-label"),
  		});
	}
	handleSubmit = () => {
		this.props.onSelectPrompt(this.state.selectedOptionText);
	}
	render() {
		const { CheckboxData } = this.state;
		return (
			<Modal
				{...this.props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered className="cust-modal multiselectcheck-modal journal-modal">
				<Modal.Header closeButton>
					<h3 className="cust-heading-center">select your journal prompt</h3>
				</Modal.Header>

				<div className="multiselectcheck-modal-body">
					<Form className="multiselectcheck-modal-Form">
						{
							CheckboxData.map((CheckboxData) => {
								return (
									<div key={CheckboxData.id} className="custom-checkbox">
										{['checkbox'].map((type) => (
											<div key={`custom-${type}`}>
												<Form.Check
													custom
													type={type}
													id={`${CheckboxData.id}`}
													label={`${CheckboxData.labelText}`}
													data-label={`${CheckboxData.labelText}`}
													value={`${CheckboxData.value}`}
            										onChange={this.changeHandler}
													checked={this.state.selectedOption == CheckboxData.value}
												/>
											</div>
										))}
									</div>
								)
							})
						}
					</Form>
				</div>

				<Modal.Footer className="justify-content-center">
				  <Button onClick={this.props.onHide} className="theme-dark-outline__btn cust-cancel-btn">cancel</Button>
				  <Button className="theme-dark__btn cust-save-btn" onClick={this.handleSubmit}>select</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default SelectJournalPrompt;