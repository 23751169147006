import React, { Component } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import HandGesture from '../../images/add-gratitude.png';
import Logo from '../../images/logo.png';
import GoalLogo from '../../images/view-goal.png';
import "../../../node_modules/font-awesome/css/font-awesome.css";
import $ from 'jquery';
import TextValidator from '../TextValidator';
import { ValidatorForm } from 'react-form-validator-core';
import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import { userActions, alertActions } from '../../actions';
import { history } from '../../helpers';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
/* import { LinkedIn } from 'react-linkedin-login-oauth2'; */


class RegisterPage extends Component {

    constructor(props) {
        super(props);

         history.listen((location, action) => {
            // clear alert on location change
            this.props.clearAlerts();
        });

        this.state = {
            user: {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                repeatPassword: "",
            },

            login: {
                email: '',
                password: '',
            },

            code: '',
            errorMessage: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleChangeLogin = this.handleChangeLogin.bind(this);
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this);

        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.user.password) {
                return false;
            }
            return true;
        });
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    handleChangeLogin(event) {
        const { name, value } = event.target;
        const { login } = this.state;
        this.setState({ 
            login: {
                ...login,
                [name]: value
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        const { user } = this.state;
        const { submitted } = this.props;
        if (user.firstName && user.lastName && user.email && user.password) {
            this.props.register(user);
            if (submitted) {
                this.setState({
                    user: {
                        firstName: '',
                        lastName: '',
                        email: '',
                        password: '',
                        repeatPassword: "",
                    },
                });
            }
        }
    }

    handleLoginSubmit(e) {
        e.preventDefault();

        const { email, password } = this.state.login;
        if (email && password) {
            this.props.login(email, password);
        }
    }

    responseFacebook = (response) => {
        let data = { accessToken: response.accessToken, userID: response.userID };
        this.props.facebookLogin(data);
    }

    responseSuccessGoogle = (response) => {
       let data = { tokenId: response.tokenId };
       this.props.googleLogin(data);
    }

    responseErrorGoogle = (response) => {
       console.log(response);
    }

    handleLinkdinSuccess = () => {
       this.props.linkedinLogin();
    }

    handleLinkdinFailure = (error) => {
        this.setState({
            code: '',
            errorMessage: error.errorMessage,
        });
    }
    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        if (params.has('code')) {
            let linkedinCode = { code: params.get('code') };
            this.props.linkedinback(linkedinCode);
        }
        this.setState({}); 
        $(function () {
            $("#login-password").on("click", function () {
                $(this).toggleClass("fa-eye fa-eye-slash");
                var type = $(this).hasClass("fa-eye-slash") ? "text" : "password";
                $(this).parents(".wrap-login-pwd").find(".txtPassword").attr("type", type);
            });
            $("#register-password").on("click", function () {
                $(this).toggleClass("fa-eye fa-eye-slash");
                var type = $(this).hasClass("fa-eye-slash") ? "text" : "password";
                $(this).parents(".wrap-register-pwd").find(".txtPassword").attr("type", type);
            });
            $("#repeat-register-password").on("click", function () {
                $(this).toggleClass("fa-eye fa-eye-slash");
                var type = $(this).hasClass("fa-eye-slash") ? "text" : "password";
                $(this).parents(".wrap-register-repeat-pwd").find(".txtPassword").attr("type", type);
            });
        });

        let switchCtn = document.querySelector("#switch-cnt");
        let switchC1 = document.querySelector("#switch-c1");
        let switchC2 = document.querySelector("#switch-c2");
        let switchCircle = document.querySelectorAll(".switch__circle");
        let switchBtn = document.querySelectorAll(".switch-btn");
        let aContainer = document.querySelector("#a-container");
        let bContainer = document.querySelector("#b-container");
        let allButtons = document.querySelectorAll(".submit");

        let getButtons = e => e.preventDefault();

        let changeForm = e => {
            switchCtn.classList.add("is-gx");
            setTimeout(function () {
                switchCtn.classList.remove("is-gx");
            }, 1500);

            switchCtn.classList.toggle("is-txr");
            switchCircle[0].classList.toggle("is-txr");
            switchCircle[1].classList.toggle("is-txr");

            switchC1.classList.toggle("is-hidden");
            switchC2.classList.toggle("is-hidden");
            aContainer.classList.toggle("is-txl");
            bContainer.classList.toggle("is-txl");
            bContainer.classList.toggle("is-z200");
        };

        let mainF = e => {
            for (var i = 0; i < allButtons.length; i++) {
                allButtons[i].addEventListener("click", getButtons);
            }
            for (var j = 0; j < switchBtn.length; j++) {
                switchBtn[j].addEventListener("click", changeForm);
            }
        };

        mainF();
    }

  
    refresh = () => {
        // re-renders the component
        this.setState({});
      };
        
    render() {
        const { registering, loggingIn } = this.props;
        return (
            <div className="page-wrap-main full-width-layout">
                <div className="wrap-login-view">
                    <div className="main main-sign-process">
                        <div className="sign-container a-container" id="a-container"><div className="wrap-sign-form-view">
                            {/* <div className="alert success--green val-messages-alert">Validation Error.</div> */}
                            {/* {alert.message &&
                                <div className={`alert val-messages-alert ${alert.type}`}>{alert.message}</div>
                            } */}
                            <img src={Logo} alt="" className="logo-sign" />
                            <h3 className="cust-heading-center">Welcome back</h3>
                            <div className="social-icons-round social-ico">
                                {/* <FacebookLogin
                                    appId="207019314326593"
                                    autoLoad={false}
                                    callback={this.responseFacebook}
                                    cssClass="facebook facebook-button"
                                    icon="fa-facebook"
                                    textButton=""
                                />
                                <GoogleLogin
                                    clientId="407904022984-ik8j5s4frjf02m9gihjaoth1o110hvv9.apps.googleusercontent.com"
                                    buttonText=""
                                    onSuccess={this.responseSuccessGoogle}
                                    onFailure={this.responseErrorGoogle}
                                    cookiePolicy={'single_host_origin'}
                                    icon={false}
                                    className="google google-button"
                                ><i className="fa fa-google"></i></GoogleLogin>
                                <a className="google google-button" onClick={this.handleLinkdinSuccess}><i className="fa fa-linkedin"></i></a> */}
                            </div>

                            <ValidatorForm className="sign-form" onSubmit={this.handleLoginSubmit}>
                                <Form.Group controlId="email">
                                    <Form.Label>Username:</Form.Label>
                                    <TextValidator type="email" placeholder="Enter Email" name="email"
                                            onChange={this.handleChangeLogin}
                                            value={this.state.login.email}
                                            validators={['required', 'isEmail']}
                                            errorMessages={['The email field is required', 'Email is not valid.']} />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Password:</Form.Label>
                                    <div className="wrap-ps wrap-login-pwd">
                                        <TextValidator type="password" placeholder="Enter password" name="password" className="txtPassword"
                                            onChange={this.handleChangeLogin}
                                            value={this.state.login.password}
                                            validators={['required']}
                                            errorMessages={['The password field is required']} />
                                            <span className="toggle_pwd fa fa-fw fa-eye field_icon" id="login-password"></span>
                                    </div>
                                </Form.Group>

                                <Form.Group className="submit-block">
                                    <button type="submit" className="theme-dark__btn submit-btn btn btn-primary" disabled={loggingIn}>sign in 
                                    {loggingIn &&
                                        <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        />}
                                    </button>
                                </Form.Group>
                            </ValidatorForm>
                            <Link to="/forgot-password" className="forget-link">Forgot your Password?</Link>
                        </div>

                        </div>
                        <div className="sign-container b-container" id="b-container">
                            {/* <div className="alert success--green val-messages-alert">Validation Error.</div> */}
                            {/* {alert.message &&
                                <div className={`alert val-messages-alert ${alert.type}`}>{alert.message}</div>
                            } */}
                            <div className="wrap-sign-form-view">
                                <img src={Logo} alt="" className="logo-sign" />
                                <h3 className="cust-heading-center">create account</h3>
                                <div className="social-icons-round social-ico">
                                     <FacebookLogin
                                    appId="133082775632689"
                                    autoLoad={false}
                                    callback={this.responseFacebook}
                                    cssClass="facebook facebook-button"
                                    icon="fa-facebook"
                                    textButton=""
                                />
                                <GoogleLogin
                                    clientId="407904022984-ik8j5s4frjf02m9gihjaoth1o110hvv9.apps.googleusercontent.com"
                                    buttonText=""
                                    onSuccess={this.responseSuccessGoogle}
                                    onFailure={this.responseErrorGoogle}
                                    cookiePolicy={'single_host_origin'}
                                    icon={false}
                                    className="google google-button"
                                ><i className="fa fa-google"></i></GoogleLogin>
                                <a className="google google-button" onClick={this.handleLinkdinSuccess}><i className="fa fa-linkedin"></i></a>
                                </div>
    
                                <ValidatorForm className="sign-form" id="sign-form" onSubmit={this.handleSubmit}>

                                    <Form.Group controlId="first-name">
                                        <Form.Label>First name:</Form.Label>
                                        <TextValidator type="text" placeholder="Enter name" name="firstName"
                                            onChange={this.handleChange}
                                            value={this.state.user.firstName}
                                            validators={['required', 'maxStringLength:30']}
                                            errorMessages={['The first name field is required', 'You can enter max 30 character in this field']} />
                                    </Form.Group>

                                    <Form.Group controlId="last-name">
                                        <Form.Label>last name:</Form.Label>
                                        <TextValidator type="text" placeholder="Enter last name" name="lastName"
                                            onChange={this.handleChange}
                                            value={this.state.user.lastName}
                                            validators={['required', 'maxStringLength:30']}
                                            errorMessages={['The last name field is required', 'You can enter max 30 character in this field']} />
                                    </Form.Group>

                                    <Form.Group controlId="email">
                                        <Form.Label>Email:</Form.Label>
                                        <TextValidator type="email" placeholder="Enter email" name="email"
                                            onChange={this.handleChange}
                                            value={this.state.user.email}
                                            validators={['required', 'isEmail']}
                                            errorMessages={['The email field is required', 'Email is not valid.']} />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Password:</Form.Label>
                                        <div className="wrap-ps wrap-register-pwd">
                                            <TextValidator type="password" placeholder="Enter password" name="password" className="txtPassword"
                                            onChange={this.handleChange}
                                            value={this.state.user.password}
                                            validators={['required']}
                                            errorMessages={['The password field is required']} />
                                            <span className="toggle_pwd fa fa-fw fa-eye field_icon" id="register-password"></span>
                                        </div>
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Repeat Password:</Form.Label>
                                        <div className="wrap-ps wrap-register-repeat-pwd">
                                            <TextValidator type="password" placeholder="Repeat Password:" name="repeatPassword" className="txtPassword"
                                            onChange={this.handleChange}
                                            value={this.state.user.repeatPassword}
                                            validators={['required', 'isPasswordMatch']}
                                            errorMessages={['The password field is required', 'password must match']} />
                                            <span className="toggle_pwd fa fa-fw fa-eye field_icon" id="repeat-register-password"></span>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="submit-block">
                                        <button type="submit" className="theme-dark__btn submit-btn btn btn-primary" disabled={registering}>sign up {registering &&
                                        <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        />}</button>
                                    </Form.Group>
                                </ValidatorForm>
                            </div>
                        </div>

                        {/* switch containers */}
                        <div className="switch" id="switch-cnt">
                            <div className="switch__circle"></div>
                            <div className="switch__circle switch__circle--t"></div>
                            <div className="switch__container" id="switch-c1">
                                <div className="splash-view">

                                    <img src={HandGesture} alt="" className="switch-img" />
                                   {/*  <h3 className="cust-heading-center cust-heading-white">hello, friend!</h3>
                                    <span>Enter your details and start your journey with us</span>
                                    <Button id="signup-splash-view-btn" className="theme-white__btn sign-btn switch__button button switch-btn" onClick={ this.refresh }>Sign up</Button> */}

                                </div>
                            </div>
                            <div className="switch__container is-hidden" id="switch-c2">
                                <div className="splash-view">

                                    <img src={GoalLogo} alt="" className="switch-img" />
                                    <h3 className="cust-heading-center switch-heading cust-heading-white">Welcome back!</h3>
                                    <span className="switch-description">To keep connected with us, please login with your personal info.</span>
                                    <Button id="signup-splash-view-btn" className="theme-white__btn sign-btn switch__button button switch-btn" disabled={loggingIn}>Sign in{loggingIn &&
                                    <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    />}</Button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { registering, submitted } = state.registration;
    const { loggingIn } = state.authentication;
    return { registering, loggingIn, submitted };
}

const actionCreators = {
    register: userActions.register,
    login: userActions.login,
    logout: userActions.logout,
    clearAlerts: alertActions.clear,
    facebookLogin: userActions.facebookLogin,
    googleLogin: userActions.googleLogin,
    linkedinLogin: userActions.linkedinLogin,
    linkedinback: userActions.linkedinback,
}

const connectedRegisterPage = connect(mapState, actionCreators)(RegisterPage);
export { connectedRegisterPage as RegisterPage };