import React from 'react';
import { Router, Switch, Redirect, withRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/fonts/fonts.css';
import './Elements.css';
import './App.css';
import './style.css';
import './responsive.css';
import 'jquery/dist/jquery';

import { history } from './helpers';
import { alertActions } from './actions';
import { PrivateRoute } from './components/PrivateRoute';
import { AfterAuthenticationPrivateRoute } from './components/AfterAuthenticationPrivateRoute';
import { RegisterPage } from './components/Pages/Signup';
import ProfileWithHeader from './components/Pages/ProfileWithHeader';
import ForgotPassword from './components/Pages/ForgotPassword';
import GratitudeLogs from './components/Pages/GratitudeLog';
import Gratitude from './components/Pages/Gratitude';
import { ToastContainer } from 'react-toastify';
import Journal from './components/Pages/Journal';
import JournalLog from './components/Pages/JournalLog';
import Dashboard from './components/Pages/Dashboard';
import Feels from './components/Pages/Feels';
import ViewLog from './components/Pages/ViewLog';
import Goals from './components/Pages/Goals';
import { List as UserList, Create as CreateUser, UserRole } from './components/Pages/Users';
import SetNewGoal from './components/Pages/SetNewGoal';
import ContentsPage from './components/Pages/ContentsPage';
import ContentsDetail from './components/Pages/ContentsDetail';
import GoalDetailPage from './components/Pages/GoalDetailPage';
import EditGoal from './components/Pages/EditGoal';
import MobileView from './components/MobileView';

import AdminRoute from './components/AdminRoute';
import PublicRoute from './components/PublicRoute';
import NotFoundRoute from './components/NotFoundRoute';

import { List as CoachingList, RequestSession  } from './components/Pages/Coaching';


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isMobileView: false};
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({isMobileView: window.innerWidth < 1024});
    }

    

    render() {
        return (
                <>
                    <ToastContainer />
                    <Router history={history}>
                        <Switch>    

                            {/* Admin Route */}

                            <AdminRoute exact path="/users/:id" component={UserRole} />
                            <AdminRoute exact path="/users" component={UserList} />


                                <PublicRoute exact path="/dashboard" component={Dashboard} />
                                <PublicRoute exact path="/gratitude/gratitude-log" component={GratitudeLogs} />
                                <PublicRoute exact path="/gratitude" component={Gratitude} />
                                <PublicRoute exact path="/journal/journal-log" component={JournalLog} />
                                <PublicRoute exact path="/journal" component={Journal} />
                                <PublicRoute exact path="/profile" component={withRouter(ProfileWithHeader)} />
                                <PublicRoute exact path="/feels" component={Feels} />
                                <PublicRoute exact path="/feels/logs" component={ViewLog} />
                                <PublicRoute exact path="/goals" component={Goals} />
                                <PublicRoute exact path="/coaching" component={CoachingList} />
                                <PublicRoute exact path="/coaching/request-session/:id" component={RequestSession} />
                                <PublicRoute exact path="/users/create" component={CreateUser} />
                                <PublicRoute exact path="/goals/new-goal" component={SetNewGoal} />
                                <PublicRoute exact path="/contents" component={ContentsPage} />
                                <PublicRoute exact path="/goals/financial-goal/:id" component={GoalDetailPage} />
                                <PublicRoute exact path="/goals/edit/:id" component={EditGoal} />
                                <PublicRoute exact path="/contents/contents-detail/:id" component={ContentsDetail} />




                            <AfterAuthenticationPrivateRoute path="/register" component={RegisterPage} />
                            <AfterAuthenticationPrivateRoute path="/forgot-password" component={ForgotPassword} />

                            <Route path='*' from="/"><NotFoundRoute /></Route>

                        </Switch>
                    </Router>
                </>   
        );
    }
}

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear
};

export default connect(mapState, actionCreators)(App);