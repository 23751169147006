import { coachingConstants } from '../constants'; 
import { coachingService } from '../services';
import 'react-toastify/dist/ReactToastify.css';

export const coachingActions = {
    getAll,
    getRow,
    sendRequest
};

function getAll() {
    return dispatch => {
        dispatch(request());
        coachingService.getAll()
            .then(
                contents => dispatch(success(contents)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: coachingConstants.GETALL_REQUEST } }
    function success(contents) { return { type: coachingConstants.GETALL_SUCCESS, contents } }
    function failure(error) { return { type: coachingConstants.GETALL_FAILURE, error } }
}

function getRow(id) {
    return dispatch => {
        dispatch(request());
        coachingService.getRow(id)
            .then(
                contents => dispatch(success(contents)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: coachingConstants.GETROW_REQUEST } }
    function success(contents) { return { type: coachingConstants.GETROW_SUCCESS, contents } }
    function failure(error) { return { type: coachingConstants.GETROW_FAILURE, error } }
}
function sendRequest(res) {
    return dispatch => {
        dispatch(request());
        coachingService.sendRequest(res)
            .then(
                contents => dispatch(success(contents)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: coachingConstants.GETROW_REQUEST } }
    function success(contents) { return { type: coachingConstants.GETROW_SUCCESS, contents } }
    function failure(error) { return { type: coachingConstants.GETROW_FAILURE, error } }
}