import React from "react";
import logo from "../../images/logo.png";
import { InputGroup, Button, Dropdown,ButtonGroup, FormControl } from "react-bootstrap";
import UserAvatarLg from "../../images/profile.svg";
import { Link } from "react-router-dom";
import { userActions, alertActions } from '../../actions';

import { connect } from 'react-redux';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.handleLogout = this.handleLogout.bind(this);
       /*  this.state = {
                        firstName: props.user.data ? props.user.data.firstName : '',
                        lastName: props.user.data ? props.user.data.lastName : '',
                        email: props.user.data ? props.user.data.email : '',
                        profileImage: props.user.data ? props.user.data.avatar : UserAvatar,
                     } */
    }

    handleLogout(event) {
       event.preventDefault();
       this.props.logout();
    }

    
    render() {
        const { user } = this.props;

        return (
            <div className="page__header">
                <div className="left-cell">

                    {
                        ( typeof user.data.role !== 'undefined' &&  user.data.role !== '' && user.data.role === '1' )?
                            <Link className="logo" to="/users">
                                <img src={logo} alt="My Goals" />
                            </Link>
                        :
                        <Link className="logo" to="/dashboard">
                            <img src={logo} alt="My Goals" />
                        </Link>

                    }

                    <InputGroup className="search-block">
                            <InputGroup.Append>
                                <Button className="search-ico"></Button>
                            </InputGroup.Append>
                            <FormControl placeholder="Search" 
                            aria-label="Amount (to the nearest dollar)"
                            />
                    </InputGroup>
                 
                </div>

                <div className="right-cell">
                      {/* <Button className="theme-dark__btn" >Upgrade</Button> */}
                      <div className="user-access">
                        <span>{user.data.firstName} {user.data.lastName}</span>
                   
                        <Dropdown id="user-access-dropdown" className="cust-dropdown">
                            <Dropdown.Toggle as={ButtonGroup} >
                                <img src={user.data.avatar ? user.data.avatar : UserAvatarLg} alt="" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <div className="media-box">
                                    <div className="media-content">
                                        <img src={user.data.avatar ? user.data.avatar : UserAvatarLg} alt="" />
                                        <div className="media-content-inside">
                                            <span>{user.data.firstName} {user.data.lastName}</span>
                                            <a href={user.data.email}>{user.data.email}</a>
                                            <Link to='/profile' className="theme-dark__btn profile-btn" >my profile</Link>
                                        </div>
                                    </div>

                                    <Dropdown.Divider />
                                        <Link className="logout-btn" to="/register" onClick={this.handleLogout}>
                                            Logout
                                        </Link>
                                    {
                                        (localStorage.getItem('user') !== '' && JSON.parse(localStorage.getItem('user'))?.data?.role )?
                                            (JSON.parse(localStorage.getItem('user')).data.role === "1" )?
                                                <Link className="logout-btn float-right" to="/users">
                                                    Go to admin
                                                </Link>
                                            :''
                                        :''
                                    }
                                    
                    
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>

                      </div>
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { user } = state;
    return { user };
}

const actionCreators = {
    logout: userActions.logout,
    clearAlerts: alertActions.clear,
}

export default connect(mapState, actionCreators)(Header);