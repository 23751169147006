
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Button, Form, Spinner } from 'react-bootstrap';
import { ValidatorForm } from 'react-form-validator-core';

import { connect } from "react-redux";
import { coachingActions } from "../../../actions";
import { Link, useParams, useHistory  } from "react-router-dom";
import TextValidator from "../../TextValidator";
import { ToastContainer, toast } from 'react-toastify';

const RequestSession = (props) => {


    const [formInputData,setFormInputData] = useState('');
    const [loading,setLoading] = useState(false);
    const params = useParams();
    const history = useHistory();

    ValidatorForm.addValidationRule('isConfirmEmail', (value) => {
            if (value !== formInputData?.email) {
                return false;
            }
            return true;
    });

    useEffect(() => {
        props.getRow(params.id);
        
    },[])


    const handleRequestSubmit = () => {
           setLoading(true);
           let items = props?.coaching?.items
           let data = {...formInputData,...items}
           props.sendContents(data);
           setLoading(false);
           history.push('/coaching');
           toast.success('Request session successfuly send.');

    }

    const onInputChange = (e) => {
        let {name, value} = e.target
        setFormInputData({...formInputData,[name]:value});
    }

    return(
        <div className="page-wrap-main">
            <Header />
            <div className="wrapper-main">
                <Sidebar />
                <div className="page-content">
                    <div className="card--white full-height contents-view">
                        <Row className="content-posts-block">
                            <Col md="12">
                                <div className="requestHeader d-flex pb-5">
                                    <Link to={'/coaching'}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                                            </svg>
                                    </Link>
                                    <div className="requestTitle w-100 text-center">
                                        <h5 className="cust-heading p-0 pb-3 mb-2 request-form-heading">Request session</h5>
                                        <span className="subheading pt-2">
                                            Shall we begin?
                                        </span>
                                    </div>
                                    <Link to={'/coaching'}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                        </svg>
                                    </Link>
                                </div>
                            </Col>

                            <ValidatorForm className="requestSessionForm row" onSubmit={handleRequestSubmit}>
                                <Col md="3">
                                    <Form.Group controlId="firstname">
                                        <Form.Label>Full Name:*</Form.Label>
                                        <TextValidator type="text" placeholder="Your answer" name="fullname"
                                                onChange={(e) => onInputChange(e) }
                                                value={formInputData?.fullname || ''}
                                                validators={['required']}
                                                errorMessages={['Full Name field is required']} />
                                    </Form.Group>
                                </Col>
                                <Col md="3">
                                <Form.Group controlId="email">
                                    <Form.Label>Your Email:*</Form.Label>
                                    <TextValidator type="email" placeholder="example@domain.com" name="email"
                                            onChange={(e) => onInputChange(e) }
                                            value={formInputData?.email || '' }
                                            validators={['required', 'isEmail']}
                                            errorMessages={['The email field is required', 'Email is not valid.']} />
                                </Form.Group>
                                </Col>
                                <Col md="3">
                                    <Form.Group controlId="confirmEmail">
                                        <Form.Label>Confirm Your Email:*</Form.Label>
                                        <TextValidator type="email" placeholder="example@domain.com" name="confirmEmail"
                                                onChange={(e) => onInputChange(e) }
                                                value={formInputData?.confirmEmail || '' }
                                                validators={['required', 'isConfirmEmail']}
                                                errorMessages={['The confirm email field is required', 'Email is not same.']} />
                                    </Form.Group>
                                </Col>
                                <Col md="3"></Col>
                                <Col md="12" className="pt-5">
                                    <Form.Group controlId="youCall">
                                        <Form.Label>Information you'd like to share with the coach before your call:*</Form.Label>
                                        <TextValidator placeholder="Start typing" name="youCall"
                                                onChange={(e) => onInputChange(e) }
                                                value={formInputData?.youCall || '' }
                                                validators={['required']}
                                                errorMessages={['The field is required']} />
                                    </Form.Group>
                                </Col>

                                <Form.Group className="submit-block w-100">
                                    <button type="submit" className="theme-dark__btn submit-btn btn btn-primary float-lg-right" disabled={loading}>Submit
                                    {loading &&
                                        <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        />}
                                    </button>
                                </Form.Group>
                            </ValidatorForm>      
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )

}

function mapState(state) {
	const { coaching } = state;
	return { coaching };
}

const actionCreators = {
	sendContents: coachingActions.sendRequest,
    getRow: coachingActions.getRow
};

export default connect(mapState, actionCreators)(RequestSession);
