import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import UserMenuBar from './Sidebar/UserMenuBar';
import AdminMenuBar from './Sidebar/AdminMenuBar';



export default function() {
    const [user,setUser] = useState('');

    useEffect(() => {

        let storageUser = localStorage.getItem('user');
        if( storageUser !== '' && typeof storageUser !== 'undefined'  ){
            let data =  JSON.parse(storageUser);
            setUser(data?.data?.role);
        }

    },[])
    
    /* return(
        <>
        {
                (user === "1"  ) ? <AdminMenuBar /> : (user === "2" || user === "1"  ) ? <UserMenuBar />: ''
        }
        </>
    )  */

    return(
        <UserMenuBar />
    )
}