import { journalConstants } from '../constants';
import { journalService } from '../services';
import { history } from '../helpers';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const journalActions = {
    getAll,
    save,
    loadMore,
    deleteJournal
};

function getAll(row) {
    return dispatch => {
        dispatch(request(row));

        journalService.getAll(row)
            .then(
                journals => dispatch(success(journals)),
                error => dispatch(failure(error.toString()))
            );
    };
  
    function request() { return { type: journalConstants.GETALL_REQUEST } }
    function success(journals) { return { type: journalConstants.GETALL_SUCCESS, journals } }
    function failure(error) { return { type: journalConstants.GETALL_FAILURE, error } }
}

function save(journal) {
    return dispatch => {
        dispatch(request(journal));

        journalService.save(journal)
            .then(
                journal => { 
                    dispatch(success());
                    toast.success(journal.message); 
                    history.push('/journal/journal-log');
                },
                error => {
                    dispatch(failure(error.toString()));
                    toast.error(error.toString()); 
                }
            );
    };

    function request(journal) { return { type: journalConstants.SAVE_REQUEST, journal } }
    function success(journal) { return { type: journalConstants.SAVE_SUCCESS, journal } }
    function failure(error) { return { type: journalConstants.SAVE_FAILURE, error } }
}

function loadMore(row) {
    return dispatch => {
        dispatch(request(row));

        journalService.getAll(row)
            .then(
                journal => dispatch(success(journal)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: journalConstants.LOAD_MORE_REQUEST } }
    function success(journal) { return { type: journalConstants.LOAD_MORE_SUCCESS, journal } }
    function failure(error) { return { type: journalConstants.LOAD_MORE_FAILURE, error } }
}

function deleteJournal(id) {
    return dispatch => {
        dispatch(request(id));

        journalService.deleteJournal(id)
            .then(
                journal => { dispatch(success(id));  toast.success(journal.message); },
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: journalConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: journalConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: journalConstants.DELETE_FAILURE, id, error } }
}