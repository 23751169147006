import React from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import $ from "jquery";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class AddStep extends React.Component {
	constructor(props) {
		super(props);
		let values = [
				{
					text: "",
					processed: false,
					processing: true,
					deadline: new Date(),
				},
			];
		this.state = {
			values: values,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentWillReceiveProps(newProps)	{
		if(newProps.steps) {
			let values = newProps.steps.length ? newProps.steps.map(item => {
						return {
							text: item.text,
							processed: true,
							processing: false,
							deadline: item.deadline ? new Date(item.deadline) : new Date(),
						};
                    }) : [
				{
					text: "",
					processed: false,
					processing: true,
					deadline: new Date(),
				},
			];
			this.setState({values: values});
		}
	}

	handleChange(i, event) {
		let values = [...this.state.values];
		values[i].text = event.target.value;
		this.setState({ values });
	}

	handleKeypress(index, event) {
		//it triggers by pressing the enter key
		if (event.key == "Enter") {
			const values = [...this.state.values];
			values[index].processed = true;
			values[index].processing = false;
			this.setState({ values: values });
		}
	}

	addClick(event) {
		event.preventDefault();
		let emptyIndex = this.state.values.findIndex(function (itm, index) {
			return itm.text == "";
		});
		if (emptyIndex != -1) {
			let index = emptyIndex + 1;
			$("input[name=step-" + index + "]").focus();
		} else {
			this.setState((prevState) => ({
				values: [
					...prevState.values.map((val) => {
						val.processed = true;
						val.processing = false;
						return val;
					}),
					{
						text: "",
						processed: false,
						processing: true,
						deadline: new Date(),
					},
				],
			}));
		}
	}

	editStep(index, event) {
		const values = [...this.state.values];
		values[index].processed = false;
		values[index].processing = true;
		this.setState({ values: values });
	}

	deleteStep(index, event) {
		const values = [...this.state.values];
		const result = [...values.slice(0, index), ...values.slice(index + 1)];
		this.setState({ values: result });
	}

	handleSubmit = () => {
		let emptyIndex = this.state.values.findIndex(function (itm, index) {
			return itm.text == "";
		});
		if (emptyIndex != -1) {
			let index = emptyIndex + 1;
			$("input[name=step-" + index + "]").focus();
		} else {
			this.props.handleStep(this.state.values);
			this.props.onHide();
		}
	};

	cancelClick() {
		/* this.setState({ values: [""]} );
		this.props.handleStep(this.state.values); */
		this.props.onHide();
	}

	setDeadlineDate = (date, i) => {
		let values = [...this.state.values];
		values[i].deadline = date;
		this.setState({ values });
	};

	render() {
		return (
			<Modal
				id="steps-modal"
				/* {...this.props} */
				show={this.props.show} onHide={this.props.onHide}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="cust-modal"
			>
				<Modal.Header closeButton>
					<h3 className="cust-heading-center">Add Step</h3>
				</Modal.Header>

				<div className="counter-block counter-step-two">
					{this.state.values.map((el, i) => {
						if (el.processed) {
							return (
								<div className="countersteps-wrap" key={i}>
									<Row className="timeline-row">
										<span className="counter disabled-counter">{i + 1}</span>
										<Row>
											<Form.Group className="edit-form-group col-md-12 custom-add-step">
												<Form.Control
													type="text"
													className="edit-title"
													placeholder="Fill in.."
													value={el.text}
													readOnly
												/>
												<Button
													className="edit-ico override-btn"
													onClick={this.editStep.bind(this, i)}
												></Button>
												{ this.state.values.length > 1 ? <Button
													className="delete-ico override-btn"
													onClick={this.deleteStep.bind(this, i)}
												></Button> : "" }
											</Form.Group>
											<Form.Group className="col-md-12">
												<Form.Label>Deadline</Form.Label>

												<div className="cust-datepicker">
													<DatePicker
														selected={el.deadline}
														/* minDate={new Date()} */
														onChange={(date) => this.setDeadlineDate(date, i)}
														placeholderText="MM/DD/YYYY"
														className="datepicker form-control"
													/>
												</div>
											</Form.Group>
										</Row>
									</Row>
								</div>
							);
						} else {
							return (
								<div className="countersteps-wrap" key={i}>
									<Row className="timeline-row">
										<span className="counter">{i + 1}</span>
										<Row>
											<Form.Group className="col-md-12">
												<Form.Label>Write a step</Form.Label>
												<Form.Control
													type="text"
													placeholder="Fill in.."
													value={el.text}
													name={`step-${i + 1}`}
													onChange={this.handleChange.bind(this, i)}
													onKeyPress={this.handleKeypress.bind(this, i)}
												/>
											</Form.Group>
											<Form.Group className="col-md-12">
												<Form.Label>Deadline</Form.Label>

												<div className="cust-datepicker">
													<DatePicker
														selected={el.deadline}
														/* minDate={new Date()} */
														onChange={(date) => this.setDeadlineDate(date, i)}
														placeholderText="MM/DD/YYYY"
														className="datepicker form-control"
													/>
												</div>
											</Form.Group>
										</Row>
									</Row>
								</div>
							);
						}
					})}
					<div className="link-btn-wrap">
						<Button
							className="theme-dark-outline__btn counter-step-two-back"
							onClick={this.cancelClick.bind(this)}
						>
							cancel
						</Button>
						<Button
							className="theme-dark-outline__btn"
							type="submit"
							onClick={this.addClick.bind(this)}
						>
							Next step
						</Button>
						<Button
							className="theme-dark__btn"
							onClick={this.handleSubmit.bind(this)}
						>
							finish
						</Button>
					</div>
					{/* </ValidatorForm> */}
				</div>
			</Modal>
		);
	}
}

export default AddStep;
