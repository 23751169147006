import React from "react";
import { Row, Button } from "react-bootstrap";
import Newsletter from "./Newsletter";
import RecentContent from "./RecentContent";
import Header from "../Pages/Header";
import Sidebar from "../Pages/Sidebar";

import { connect } from "react-redux";
import { contentActions } from "../../actions";
import * as moment from "moment";

class ContentsDetail extends React.Component {

	componentDidMount() {
		const id = this.props.match.params.id;
		this.props.getDetails(id);
	}

	// handle input value coming from the child component
	handleRecentDetails = (val) => {
		this.props.getDetails(val);
	}
	render() {
		const { contents } = this.props;
		return (
			<div className="page-wrap-main">
				<Header />

				<div className="wrapper-main">
					<Sidebar />

					<div className="page-content">
						{contents.item && 
						<div className="card--white full-height contents-detail-view">
							<div className="cust-head-center-wrap">
								<Button
									className="back-arrow"
									onClick={() => this.props.history.goBack()}
								></Button>
								<span className="top-heading">Content</span>
								<h2 className="cust-heading-center">
									{ contents.item.acf.title }
								</h2>
								<span className="author content-detail-autor">
									By { contents.item.acf.author_details.name } &#x02223; <span>{moment(contents.item.acf.date, "DD/MM/YYYY").format("MMMM DD, YYYY")}</span>
								</span>
							</div>
							<Row className="content-posts-block container-med content-details-wrap">
								<div className="content-cell">
									<div className="img-view-box">
										<img src={ contents.item.acf.post_image } alt="" />
										<span className="cat-label">{ contents.item.post_categories[0].name }</span>
										<div className="social-ico">
											<a href={ contents.item.acf.social_media.facebook_link } className="facebook" target="_blank"></a>
											<a href={ contents.item.acf.social_media.twitter } className="twitter" target="_blank"></a>
											<a href={ contents.item.acf.social_media.linkedin } className="linekdin" target="_blank"></a>
										</div>
									</div>

									<div className="content-detail-container-sm" dangerouslySetInnerHTML={{__html: contents.item.acf.content_section_1}} />

									<div className="blockquote-block">
										<blockquote dangerouslySetInnerHTML={{__html: contents.item.acf.blockquote.left_section}}>
										</blockquote>

										<p dangerouslySetInnerHTML={{__html: contents.item.acf.blockquote.right_section}}></p>
									</div>
									<div className="content-detail-container-sm" dangerouslySetInnerHTML={{__html: contents.item.acf.content_section_2}}>
									</div>
								</div>
							</Row>

							<div className="content-media-box">
								<div className="content-detail-container-sm">
									<img src={ contents.item.acf.author_details.image } alt="" />
									<div className="content-media-content">
										<span>{ contents.item.acf.author_details.name }</span>
										<p>
											{ contents.item.acf.author_details.detail }
										</p>
									</div>
								</div>
							</div>
							<Newsletter />
							<div className="wrap-recent-block">
								<h2 className="cust-heading-center">related content</h2>
								<RecentContent category={ this.props.contents.item.post_categories[0].term_id } handleRecent={this.handleRecentDetails} />
							</div>
						</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

function mapState(state) {
	const { contents } = state;
	return { contents };
}

const actionCreators = {
	getDetails: contentActions.getDetails,
};

export default connect(mapState, actionCreators)(ContentsDetail);