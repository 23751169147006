import { authHeader } from '../helpers';
export const goalService = {
    getAll,
    save,
    getAllCategories,
    getDetails,
    getGoalSteps,
    updateGoalSteps,
    deleteGoal,
    update
};

function getAll(row) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/goals?row=${row}`, requestOptions).then(handleResponse);
}

function save(goal) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(goal)
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/goals`, requestOptions).then(handleResponse);
}

function getAllCategories() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/categories`, requestOptions).then(handleResponse);
}

function getDetails(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/goals/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data.data && data.data[0].msg) || data.message;
            return Promise.reject(error);
        }
        return data;
    });
}

function getGoalSteps(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/goals/steps/${id}`, requestOptions).then(handleResponse);
}

function updateGoalSteps(id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/goals/steps/${id}`, requestOptions).then(handleResponse);
}

function deleteGoal(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/goals/${id}`, requestOptions).then(handleResponse);
}

function update(id, data) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/goals/${id}`, requestOptions).then(handleResponse);
}