import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";
import * as moment from "moment";

import { connect } from "react-redux";
import { userfeelActions } from "../../actions";

import Header from "../Pages/Header";
import Sidebar from "../Pages/Sidebar";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class ViewLog extends React.Component {
  componentDidMount() {
    this.props.getAll(this.row);
  }

  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
    };
    this.row = 0;
    this.rowperpage = 5;
    this.buttonText = "Load More";
    this.loadMore = this.loadMore.bind(this);
  }

  loadMore(e) {
    e.preventDefault();
    this.row += this.rowperpage;
    this.props.loadMore(this.row);
    this.buttonText = "Load More";
    if (this.row >= this.props.userfeels.totalNumberOfRows) {
         this.setState({ disabled: true });
    }
  }

   get getButtonText() {
    if (this.props.userfeels.loading) return 'Loading...';
    if (this.props.userfeels.error) return 'Error has occurred :(';
    return 'Load more'
  }

  submit = (e) => {
    let id = e.target.getAttribute('id');
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete this feel.',
      buttons: [
        {
          label: 'Yes',
          className: 'theme-dark-outline__btn cust-cancel-btn btn btn-primary',
          onClick: () => this.deleteFeel(id)
        },
        {
          label: 'No',
          className: 'theme-dark__btn cust-save-btn btn btn-primary'
        }
      ]
    });
  };

  deleteFeel = (id) => {
    this.props.deleteFeel(id);
  }

  render() {
    const { userfeels } = this.props;
    const allFeels = userfeels.items ? userfeels.items : [];
    return (
      <div className="page-wrap-main">
        <Header />

        <div className="wrapper-main">
          <Sidebar />

          <div className="page-content">
            <div className="card--white full-height log-view">
              <div className="cust-head-center-wrap">
                <Button
                  className="back-arrow"
                  onClick={() => this.props.history.goBack()}
                ></Button>
                <span className="top-heading">feels log</span>
                <h2 className="cust-heading-center">How Did You Feel?</h2>
              </div>
              { allFeels.length > 0 ?
              (<Row className="feels-log-row">
                <Col sm={6} className="feels-log-col">
                  <h3>Physically?</h3>

                  <ListGroup className="feels-log-group">
                    {userfeels.items &&
                      userfeels.items.map((feel, index) => {
                        if (feel.physicallyFeel === null)
                          return (
                            <ListGroup.Item key={feel._id}>
                              <div>
                                <span className="feels-log-emoji">
                                  {feel.physicallyEmoji}
                                </span>
                                <span style={{ textTransform: "capitalize" }}>
                                  { feel.physically ? feel.physically.replace(/_/g, ' ') : "N/A" }
                                </span>
                              </div>
                             {/*  <span>
                                {moment(feel.createdAt).format("MMMM DD, YYYY")}
                              </span> */}
                            </ListGroup.Item>
                          );
                        if (feel.physicallyFeel)
                        return (
                          <ListGroup.Item key={feel._id}>
                            <div>
                              <img
                                src={feel.physicallyFeel.image}
                                alt={feel.physicallyFeel.name}
                              />
                              <span style={{ textTransform: "capitalize" }}>
                                {feel.physicallyFeel.name}
                              </span>
                            </div>
                            {/* <span>
                              {moment(feel.createdAt).format("MMMM DD, YYYY")}
                            </span> */}
                          </ListGroup.Item>
                        );
                      })}
                  </ListGroup>
                </Col>

                <Col sm={6} className="feels-log-col">
                  <h3>Emotionally?</h3>

                  <ListGroup className="feels-log-group">
                    {userfeels.items &&
                      userfeels.items.map((feel, index) => {
                        if (feel.emotionallyFeel === null)
                          return (
                            <ListGroup.Item key={feel._id}>
                              <div>
                                <span className="feels-log-emoji">
                                  {feel.emotionallyEmoji}
                                </span>
                                <span style={{ textTransform: "capitalize" }}>
                                  {feel.emotionally ? feel.emotionally.replace(/_/g, ' ') : "N/A"}
                                </span>
                              </div>
                              <span>
                                {moment(feel.createdAt).format("MMMM DD, YYYY")}
                              </span>
                              <button className="ml-2 delete-ico" id={feel._id} onClick={this.submit}></button>
                            </ListGroup.Item>
                          );
                        if (feel.emotionallyFeel)
                        return (
                          <ListGroup.Item key={feel._id}>
                            <div>
                              <img
                                src={feel.emotionallyFeel.image}
                                alt={feel.emotionallyFeel.name}
                              />
                              <span style={{ textTransform: "capitalize" }}>
                                {feel.emotionallyFeel.name}
                              </span>
                            </div>
                            <span>
                              {moment(feel.createdAt).format("MMMM DD, YYYY")}
                            </span>
                            <button className="ml-2 delete-ico" id={feel._id} onClick={this.submit}></button>
                          </ListGroup.Item>
                        );
                      })}
                  </ListGroup>
                </Col>
              </Row>)
              : (<span className="no-data-found text-center">You have not added your feels yet</span>)}

              <div className="link-btn-wrap">
                  { this.row < userfeels.totalNumberOfRows  && userfeels.totalNumberOfRows > 5 &&
                    <button className="theme-dark-outline__btn load-more-btn" onClick={this.loadMore} disabled={this.state.disabled}>
                      {this.getButtonText}
                    </button>
                  }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { userfeels } = state;
  return { userfeels };
}

const actionCreators = {
  getAll: userfeelActions.getAll,
  loadMore: userfeelActions.loadMore,
  deleteFeel: userfeelActions.deleteFeel,
};

export default connect(mapState, actionCreators)(ViewLog);
