import React from "react";
import { Row, Col } from "react-bootstrap";
import FeatureImg from "../../images/blog-images/blog-1.png";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { contentActions } from "../../actions";

import * as moment from "moment";

class RecentContent extends React.Component {
	componentDidMount() {
		this.props.getAllRelatedContents(this.props.category);
	}
	scrollToTop(id, cat_id) {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
		this.props.handleRecent(id);
		this.props.getAllRelatedContents(cat_id);
  	}
	render() {
		const { contents } = this.props;

		return (
			<Row className="content-posts-block content-posts-sm">
				{contents.related &&
					contents.related.map((content, index) => {
						const desc = content.acf.content_section_1.replace(/<[^>]+>/g, "");
						return (
							<Col xl={4} lg={6} className="content-posts-cell" key={index}>
								<Link
									className="content-cell"
									to={"/contents/contents-detail/" + content.id}
									onClick={() => this.scrollToTop(content.id, content.post_categories[0].term_id)} >
									<div className="img-view-box">
										<img src={content.acf.post_image} alt="" />
										<span className="cat-label">
											{content.post_categories[0].name}
										</span>
									</div>
									<h2>{content.acf.title} </h2>
									<p>{desc.substring(0, 100)}</p>

									<span className="author">
										By {content.acf.author_details.name} &#x02223;{" "}
										<span>
											{moment(content.acf.date, "DD/MM/YYYY").format(
												"MMMM DD, YYYY"
											)}
										</span>
									</span>
								</Link>
							</Col>
						);
					})}
			</Row>
		);
	}
}

function mapState(state) {
	const { contents } = state;
	return { contents };
}

const actionCreators = {
	getAllRelatedContents: contentActions.getAllRelatedContents,
};

export default connect(mapState, actionCreators)(RecentContent);
