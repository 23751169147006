import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Header from "../Header";
import Sidebar from "../Sidebar";

import { connect } from "react-redux";
import { coachingActions } from "../../../actions";
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import View from "./View";


const List = (props) => {

    const [modalShow, setModalShow] = useState(false);
    const [viewData, setViewData ] = useState([]);
    useEffect(() => {
        props.getContents();
    },[]);

    const modalView = (data) => {
        setModalShow(true);
        setViewData(data)
    }

    const handleClose = (popupReturn) => {
        setModalShow(popupReturn)
    }

    
    return(
        <div className="page-wrap-main">
            <Header />
            <div className="wrapper-main">
                <Sidebar />
                <div className="page-content">
                    <div className="card--white full-height contents-view">
                        <h3 className="cust-heading">Coaching</h3>
                        <span className="subheading">
                            Book a session with a coach that suits you.
                        </span>
                        <Row className="content-posts-block scroll-x-auto pt-5">
                            {
                                (props?.coaching?.items?.length  > 0)?
                                    props?.coaching?.items?.map(( { id, acf, title } ) => 
                                        <Col xl="4" lg="6" md="2"  className="mb-5 pb-5 mt-2">
                                        <Card style={{ width: '100%' }} className="mt-3 position-relative cards-main pr-3 pb-3 pl-3 border-0 mh-100 h-100" >
                                            <Card.Body>
                                                <img src={acf.image?.sizes?.thumbnail} className="card-main-image"  />
                                                <Card.Title className="text-center pb-2 mh-5">{ title.rendered }</Card.Title>
                                                <Card.Subtitle className="mb-2 text-muted text-center pb-3">{acf.job_title}</Card.Subtitle>
                                                <Card.Text className="pb-1 font-weight-bold">
                                                    {acf.bio_title}
                                                </Card.Text>
                                                <Card.Text className="pb-4 card-main-content cp" onClick={() => modalView({id:id, acf:acf,title:title }) } >
                                                    { acf.bio_content.replace( /(<([^>]+)>)/ig, '').slice(0,150) +  ( (acf?.bio_content?.length > 150 )?'...':'' ) }
                                                    <span className="d-block text-dark text-underline pt-1" onClick={() => modalView({id:id, acf:acf,title:title }) } ><ins>View More</ins></span>
                                                </Card.Text>
                                                <div className="link-btn-wrap card-btn-main"><Link className="theme-dark__btn" to={`/coaching/request-session/${id}`}>request session</Link></div>
                                            </Card.Body>
                                        </Card>
                                        </Col>
                                     )
                                :''
                            }
                        </Row>
                    </div>
                </div>
            </div>
            <View modalviewdata = {viewData} show={modalShow} handleclose={handleClose} />
        </div>

    )
}

/* export default ContentsPage; */

function mapState(state) {
	const { coaching } = state;
	return { coaching };
}

const actionCreators = {
	getContents: coachingActions.getAll,
};

export default connect(mapState, actionCreators)(List);