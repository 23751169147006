import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";

import CustomFeeling from "../Pages/Modals/CustomFeeling";
import { Link } from "react-router-dom";

import Header from "../Pages/Header";
import Sidebar from "../Pages/Sidebar";

import { connect } from "react-redux";
import { feelActions, userfeelActions } from "../../actions";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Feels extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {
      modalShow: false,
      physicallyFeel: "",
      emotionallyFeel: "",
      customFeelType: "",
      customPhysicallEmoji: "",
      customEmotinalEmoji: "",
      customPhysicallName: "",
      customEmotinalName: "",
    };
  }

  componentDidMount() {
    this.props.getfeels();
  }
  changeHandler = (e) => {
    this.setState({
      physicallyFeel: e.target.value,
      customPhysicallEmoji: "",
    });
  };
  changeEmotionHandler = (e) => {
    this.setState({
      emotionallyFeel: e.target.value,
      customEmotinalEmoji: "",
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    if ((this.state.physicallyFeel || this.state.customPhysicallEmoji) && (this.state.emotionallyFeel || this.state.customEmotinalEmoji)) {
      if (this.state.physicallyFeel && this.state.emotionallyFeel) {
        var data = {
          physicallyFeel:this.state.physicallyFeel,
          emotionallyFeel:this.state.emotionallyFeel,
        }
      }
      else if (this.state.customPhysicallEmoji && this.state.customEmotinalEmoji) {
        var data = {
								physically: this.state.customPhysicallName,
								physicallyEmoji: this.state.customPhysicallEmoji,
                emotionally: this.state.customEmotinalName,
								emotionallyEmoji: this.state.customEmotinalEmoji,
				}
      }
      else if (this.state.physicallyFeel && this.state.customEmotinalEmoji) {
        var data = {
								physicallyFeel:this.state.physicallyFeel,
                emotionally: this.state.customEmotinalName,
								emotionallyEmoji: this.state.customEmotinalEmoji,
				}
      }
      else if (this.state.customPhysicallEmoji && this.state.emotionallyFeel) {
        var data = {
								physically: this.state.customPhysicallName,
								physicallyEmoji: this.state.customPhysicallEmoji,
                emotionallyFeel:this.state.emotionallyFeel,
				}
      }
      

      this.props.save(data);

      this.setState({
        physicallyFeel: "",
        emotionallyFeel: "",
      });

    }
    else {
      toast.error("Please select feels first"); 
    }
  }
  handleCustom = (value) => {
    if (this.state.customFeelType=="physically") {
      this.setState({ modalShow: false, customPhysicallEmoji: value.physicallyEmoji, customPhysicallName: value.physically });
    } else {
      this.setState({ modalShow: false, customEmotinalEmoji: value.emotionallyEmoji, customEmotinalName: value.emotionally  });
    }
  };

  handlebaseUrl = (img) => {
    let imgUrl = img.split('/');
    let imageArray = [];
    for (let key in imgUrl) {
        if( key > 2 ){
            imageArray.push(imgUrl[key]);
        }
    }
    let imageJoin = imageArray.join('/');
    return '/' + imageJoin;
    
    
  }

  render() {
    const { feels } = this.props;
    let modalClose = () => this.setState({ modalShow: false });
    return (
      <div className="page-wrap-main">
        <Header />

        <div className="wrapper-main">
          <Sidebar />

          <div className="page-content">
            <div className="card--white full-height feels-view">
              <div className="cust-heading-wrap">
                <h3 className="cust-heading cust-heading-view">Feels</h3>
                <Link className="view__btn" to="/feels/logs">
                  View log <span className="eye-ico"></span>
                </Link>
              </div>
              <p className="sub-heading">
                How are you feeling this morning physically?
              </p>
              <Row className="mood-feels-row">
                <div className="mood-feels-row-left">
                  <div className="mood-feels-scroll">
                    {feels.items &&
                      feels.items.map((feel, index) => {
                        
                        if (feel.type === "physically")
                          return (
                            <Col className="mood-feels-cell" key={feel._id}>
                              <div className="mood-feels-icons">
                                <div className="mood-feels-img-wrap">
                                  <img src={ this.handlebaseUrl(feel.image) } alt="Sleepy" />
                                </div>

                                <Form className="custom-radio">
                                  <Form.Check
                                    custom
                                    type="radio"
                                    id={feel._id}
                                    label={feel.name}
                                    onChange={this.changeHandler}
                                    value={feel._id}
                                    checked={
                                      this.state.physicallyFeel == feel._id
                                    }
                                  />
                                </Form>
                              </div>
                            </Col>
                          );
                      })}
                  </div>
                </div>

                <div className="mood-feels-cell mood-feels-update-cell">
                  <div className="mood-feels-icons">
                    <div className="mood-feels-img-wrap">
                      <span className={"custom-icon " + (this.state.customPhysicallEmoji ? 'custom-emoji' : '')}>{ this.state.customPhysicallEmoji ? this.state.customPhysicallEmoji : "Custom icon"}</span>
                    </div>

                    <div className="link-btn-wrap">
                      <Button
                        onClick={() => this.setState({ modalShow: true, customFeelType: "physically", physicallyFeel: "" })}
                        className="theme-dark-outline__btn"
                      >
                        Custom
                      </Button>

                      { this.state.modalShow && <CustomFeeling
                        show={this.state.modalShow}
                        onHide={modalClose}
                        type={this.state.customFeelType}
                        customemoji={this.handleCustom}
                      /> }
                    </div>
                  </div>
                </div>
              </Row>

              <p className="sub-heading">
                How are you feeling this morning emotionally?
              </p>
              <Row className="mood-feels-row">
                <div className="mood-feels-scroll clearfix">
                  {feels.items &&
                    feels.items.map((feel, index) => {
                      if (feel.type === "emotionally")
                        return (
                          <Col className="mood-feels-cell" key={feel._id}>
                            <div className="mood-feels-icons">
                              <div className="mood-feels-img-wrap">
                                <img src={ this.handlebaseUrl(feel.image) } alt="Sleepy" />
                              </div>

                              <Form className="custom-radio">
                                <Form.Check
                                  custom
                                  type="radio"
                                  id={feel._id}
                                  label={feel.name}
                                  onChange={this.changeEmotionHandler}
                                  value={feel._id}
                                  checked={
                                    this.state.emotionallyFeel == feel._id
                                  }
                                />
                              </Form>
                            </div>
                          </Col>
                        );
                    })}
                </div>

                <div className="mood-feels-cell mood-feels-update-cell">
                  <div className="mood-feels-icons">
                    <div className="mood-feels-img-wrap">
                      <span className={"custom-icon " + (this.state.customEmotinalEmoji ? 'custom-emoji' : '')}>{ this.state.customEmotinalEmoji ? this.state.customEmotinalEmoji : "Custom icon"}</span>
                    </div>

                    <div className="link-btn-wrap">
                      <Button
                        onClick={() => this.setState({ modalShow: true, customFeelType: "emotionally", emotionallyFeel: "" })}
                        className="theme-dark-outline__btn"
                      >
                        Custom
                      </Button>

                       { this.state.modalShow && <CustomFeeling
                        show={this.state.modalShow}
                        onHide={modalClose}
                        type={this.state.customFeelType}
                        customemoji={this.handleCustom}
                      /> }
                    </div>
                  </div>
                </div>
              </Row>
              <div className="link-btn-wrap common-view-foot">
                <a href="#" className="theme-dark__btn" onClick={this.handleSubmit}>
                  Update
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { feels } = state;
  return { feels };
}

const actionCreators = {
  getfeels: feelActions.getAll, 
  save: userfeelActions.save, 
};

export default connect(mapState, actionCreators)(Feels);
