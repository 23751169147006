import React from "react";
import { Row, Col } from "react-bootstrap";
import Setnewgoal from "../../images/set-new-goal.png";

import Addgratitude from "../../images/add-gratitude.png";

import What_illustration from "../../images/card-gold.png";


import Readlearn from "../../images/Read-Learn.png";
import Viewgoal from "../../images/view-goal.png";
import Newentry from "../../images/New-entry.png";
import { Link } from "react-router-dom";

import Header from "../Pages/Header";
import Sidebar from "../Pages/Sidebar";

import { connect } from "react-redux";
import { userfeelActions } from "../../actions";

import * as moment from "moment";

class Dashboard extends React.Component {
  componentDidMount() {
    this.props.getAll();
  }

  handlebaseUrl = (img) => {
    let imgUrl = img.split('/');
    let imageArray = [];
    for (let key in imgUrl) {
        if( key > 2 ){
            imageArray.push(imgUrl[key]);
        }
    }
    let imageJoin = imageArray.join('/');
    return '/' + imageJoin;
    
    
  }

  render() {
    const { user, userfeels } = this.props;
    const todayFeels = userfeels.items ? userfeels.items.filter((item) => { return (moment().format("YYYY-MM-DD")==moment(item.createdAt).format("YYYY-MM-DD")) })[0] : {};
    return (
      <div className="page-wrap-main">
        <Header />

        <div className="wrapper-main">
          <Sidebar />

          <div className="page-content">
            <div className="dashboard-view">
              <div className="greeting-card cust_shadow">
                <Row>
                  <Col md="4" className="d-flex justify-center align-items-center">
                    <div className="cust-top">
                      <h2>
                        Hi {user.data.firstName} {user.data.lastName},
                      </h2>
                      <span>We have a few things for you to look at.</span>
                    </div>
                  </Col>
                  <Col md="8">
                  <div className="row align-items-end you_feel">
                    <Col md="4">
                    <h2>
                      Feels
                    </h2>
                            <span>How do you feel today? </span>
                    </Col>
                    <Col md="8">
                     <div className=" d-flex">
                      
                      <div className=" d-flex justify-content-between w-100 align-items-end">
                        { todayFeels ? todayFeels.physicallyFeel == null ? (
                          <div className="feel-cells d-flex align-items-center">
                              <h3>Physical:</h3>
                              <span style={{ textTransform: "capitalize" }} className="emoji-content px-1">
                                { todayFeels.physically ? todayFeels.physically.replace(/_/g, ' ') : "N/A" }
                              </span>
                              <span className="feels-log-emoji">
                                {todayFeels.physicallyEmoji}
                              </span>
                          </div>
                        ) : (
                          <div className="feel-cells d-flex align-items-center">
                            <h3>Physical:</h3>
                            <span className="emoji-content px-1">{ todayFeels.physicallyFeel.name }</span>
                            {/* <img src={ this.handlebaseUrl(todayFeels.physicallyFeel.image) } width={'25px'} /> */}
                            <img src={ todayFeels.physicallyFeel.image } width={'25px'} />
                        </div>
                        ) : <div className="feel-cells d-flex align-items-end">
                              <h3>Physical:</h3>
                              <span className="greeting-card-info">N/A</span>
                            </div>}

                        {todayFeels ? todayFeels.emotionallyFeel == null ? (
                          <div className="feel-cells d-flex align-items-center">
                              <h3>Emotion:</h3>
                              <span style={{ textTransform: "capitalize" }} className="emoji-content px-1">
                                { todayFeels.emotionally ? todayFeels.emotionally.replace(/_/g, ' ') : "N/A" }
                              </span>
                              <span className="feels-log-emoji">
                                {todayFeels.emotionallyEmoji}
                              </span>
                          </div>
                        ) : (
                          <div className="feel-cells d-flex align-items-center align-items-end">
                            <h3>Emotion:</h3>
                            <span className="emoji-content px-1">{ todayFeels.emotionallyFeel.name }</span>
                            {/* <img src={ this.handlebaseUrl(todayFeels.emotionallyFeel.image) } width={'25px'} /> */}
                            <img src={ todayFeels.emotionallyFeel.image } width={'25px'} />
                        </div>
                        ) : <div className="feel-cells d-flex">
                              <h3>Emotion:</h3>
                              <span className="greeting-card-info">N/A</span>
                        </div>}
                        <div className="link-btn-wrap">
                          <Link to="/feels" className="theme-dark__btn">
                            Update
                          </Link>
                        </div>
                      </div>
                      
                    </div>

                    </Col>

                    </div>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col xl={8}>
                  <div className="card--white">
                    <h3 className="cust-heading">goals</h3>
                    <div className="row">
                      <Col lg={6} className="divider">
                        <img
                          src={Setnewgoal}
                          alt="Set New Goal"
                          className="resp-img"
                        />
                        <div className="link-btn-wrap">
                          <Link to="/goals/new-goal" className="theme-dark__btn">
                            set new goal
                          </Link>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <img
                          src={Viewgoal}
                          alt="View Goal"
                          className="resp-img"
                        />
                        <div className="link-btn-wrap">
                          <Link
                            to="/goals"
                            className="theme-dark__btn"
                          >
                            view goal
                          </Link>
                        </div>
                      </Col>
                    </div>
                  </div>
                </Col>

                <Col xl={4} lg={6}>
                  <div className="card--white feels-block">
                    <h3 className="cust-heading">Coaching</h3>
                   
                          <img
                            src={What_illustration}
                            alt="Add gratitude"
                            className="resp-img"
                          />
                                      
                    <div className="link-btn-wrap">
                      <Link to="/coaching" className="theme-dark__btn cust-pt-20">
                        View all
                      </Link>
                    </div>
                  </div>
                </Col>

                <Col xl={4} lg={6}>
                  <div className="card--white">
                    <h3 className="cust-heading">gratitude</h3>
                    <img
                      src={Addgratitude}
                      alt="Add gratitude"
                      className="resp-img"
                    />
                    <div className="link-btn-wrap">
                      <Link to="/gratitude" className="theme-dark__btn">
                        Add gratitude
                      </Link>
                    </div>
                  </div>
                </Col>

                <Col xl={4} lg={6}>
                  <div className="card--white">
                    <h3 className="cust-heading">journal</h3>
                    <img src={Newentry} alt="New entry" className="resp-img" />
                    <div className="link-btn-wrap">
                      <Link to="/journal" className="theme-dark__btn">
                        New entry
                      </Link>
                    </div>
                  </div>
                </Col>

                <Col xl={4} lg={6}>
                  <div className="card--white">
                    <h3 className="cust-heading">content</h3>
                    <img
                      src={Readlearn}
                      alt="Read learn"
                      className="resp-img"
                    />
                    <div className="link-btn-wrap">
                      <Link to="/contents" className="theme-dark__btn">
                        Read and Learn
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { user, userfeels } = state;
  return { user, userfeels };
}

const actionCreators = {
  getAll: userfeelActions.getAll,
};
export default connect(mapState, actionCreators)(Dashboard);
