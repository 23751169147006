import { userfeelConstants } from '../constants';
import { userfeelService } from '../services';
import { history } from '../helpers';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const userfeelActions = {
    getAll,
    save,
    loadMore,
    deleteFeel
};

function getAll(row) {
    return dispatch => {
        dispatch(request(row));

        userfeelService.getAll(row)
            .then(
                userfeels => dispatch(success(userfeels)),
                error => dispatch(failure(error.toString()))
            );
    };
  
    function request() { return { type: userfeelConstants.GETALL_REQUEST } }
    function success(userfeels) { return { type: userfeelConstants.GETALL_SUCCESS, userfeels } }
    function failure(error) { return { type: userfeelConstants.GETALL_FAILURE, error } }
}

function save(userfeel, type) {
    return dispatch => {
        dispatch(request(userfeel));

        userfeelService.save(userfeel, type)
            .then(
                userfeel => { 
                    dispatch(success());
                    toast.success(userfeel.message); 
                    history.push('/feels/logs');
                },
                error => {
                    dispatch(failure(error.toString()));
                    toast.error(error.toString()); 
                }
            );
    };

    function request(userfeel) { return { type: userfeelConstants.SAVE_REQUEST, userfeel } }
    function success(userfeel) { return { type: userfeelConstants.SAVE_SUCCESS, userfeel } }
    function failure(error) { return { type: userfeelConstants.SAVE_FAILURE, error } }
}

function loadMore(row) {
    return dispatch => {
        dispatch(request(row));

        userfeelService.getAll(row)
            .then(
                userfeel => dispatch(success(userfeel)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userfeelConstants.LOAD_MORE_REQUEST } }
    function success(userfeel) { return { type: userfeelConstants.LOAD_MORE_SUCCESS, userfeel } }
    function failure(error) { return { type: userfeelConstants.LOAD_MORE_FAILURE, error } }
}

function deleteFeel(id) {
    return dispatch => {
        dispatch(request(id));

        userfeelService.deleteFeel(id)
            .then(
                userfeel => { dispatch(success(id));  toast.success(userfeel.message); },
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: userfeelConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userfeelConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userfeelConstants.DELETE_FAILURE, id, error } }
}