import React from "react";
import { Button, Row, Form, Col } from "react-bootstrap";
import AddStep from "../Pages/Modals/AddStep";
import Header from "../Pages/Header";
import Sidebar from "../Pages/Sidebar";

import { connect } from "react-redux";
import { goalActions } from "../../actions";

import TextValidator from "../TextValidator";
import { ValidatorForm } from "react-form-validator-core";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as moment from "moment";

class EditGoal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalShow: false,
			open: false,
			selectedCategory: "Select one",
			title: "",
			obstacles: "",
			commitment: "",
			celebration: "",
			category: "",
			startDate: new Date(),
			endDate: new Date(),
			customCategory: "",
			steps: [],
		};
		this.handleStepValue = this.handleStepValue.bind(this);
	}

	componentDidMount() {
		const id = this.props.match.params.id;
		const { getDetails, getAllCategories } = this.props;
		getAllCategories();
		getDetails(id);
		let params = new URLSearchParams(window.location.search);
		if (params.has("step")) {
			this.setState({ modalShow: true });
		}
	}

	componentWillReceiveProps(newProps) {
		if (newProps.goals.details) {
			let steps = newProps.goals.details.steps
				? newProps.goals.details.steps.map((item) => {
						return {
							text: item.title,
							deadline: item.deadline,
						};
				  })
				: [];
			this.setState({
				title: newProps.goals.details.title,
				obstacles: newProps.goals.details.obstacle,
				commitment: newProps.goals.details.commitment,
				celebration: newProps.goals.details.celebration,
				steps: steps,
				startDate: new Date(newProps.goals.details.startDate),
				endDate: new Date(newProps.goals.details.completionDate),
				selectedCategory: newProps.goals.details.category ? newProps.goals.details.category.title : "",
				category: newProps.goals.details.category ? newProps.goals.details.category._id : "",
			});
		}
	}

	// handle input value coming from the child component
	handleStepValue(val) {
		this.setState({ steps: val });
	}

	handleChange = (event) => {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
		});
	};
	handleSubmit = (event) => {
		event.preventDefault();
		let addedSteps = [];
		this.state.steps.forEach(function (item, index) {
			if (item.text != "") {
				let steps = {
					text: item.text,
					deadline: item.deadline,
				};
				addedSteps.push(steps);
			}
		});

		let data = {
			category: this.state.category
				? this.state.category
				: this.state.customCategory,
			title: this.state.title,
			startDate: this.state.startDate,
			completionDate: this.state.endDate,
			commitment: this.state.commitment,
			obstacle: this.state.obstacles,
			celebration: this.state.celebration,
			userSteps: addedSteps,
		};
		this.props.update(this.props.match.params.id, data);
	};

	toggleDropdown = () => {
		this.setState({ open: !this.state.open });
	};

	addCategory = (event) => {
		this.setState({
			category: event.target.getAttribute("data-id"),
			selectedCategory: event.target.getAttribute("data-text"),
		});
	};

	setStartDate = (date) => {
		this.setState({
			startDate: date,
		});
	};

	setEndDate = (date) => {
		this.setState({
			endDate: date,
		});
	};

	addCustomCategory = (event) => {
		event.stopPropagation();
	};

	handleCustomCategory = (event) => {
		if (event.key === "Enter") {
			this.setState({
				customCategory: event.target.value,
				open: !this.state.open,
				selectedCategory: event.target.value,
				category: "",
			});
			event.preventDefault();
		}
	};

	handleCustomChange = (event) => {
		this.setState({
			customCategory: event.target.value,
			selectedCategory: event.target.value,
			category: "",
		});
		event.preventDefault();
	};

	handleCatList = () => {
		this.setState({
			open: !this.state.open,
		});
	}

	render() {
		let modalClose = () => this.setState({ modalShow: false });
		const { goals } = this.props;
		return (
			<div className="page-wrap-main">
				<Header />

				<div className="wrapper-main">
					<Sidebar />

					<div className="page-content">
						<div className="card--white full-height setnewgoal-view">
							<div className="cust-head-center-wrap">
								<Button
									className="back-arrow"
									onClick={() => this.props.history.goBack()}
								></Button>
								<h2 className="cust-heading-center">Update goal</h2>
							</div>

							<div className="newgoal-timeline">
								<ValidatorForm
									className="goal-form"
									onSubmit={this.handleSubmit}
								>
									<Row className="timeline-row">
										<Form.Group className="col-md-4">
											<Form.Label>
												<h2>Category & Title:</h2>
												Goal category: <span>*</span>
											</Form.Label>

											<div
												className="cust-select"
												onClick={this.toggleDropdown}
											>
												<span className="to-show arrow-down init placeholder-text">
													{this.state.selectedCategory}
												</span>
												{this.state.open && (
													<div className="select-options">
														<ul>
															{goals.categories &&
																goals.categories.map((categoery, index) => {
																	return (
																		<li
																			data-id={categoery._id}
																			key={categoery._id}
																			data-text={categoery.title}
																			onClick={this.addCategory}
																		>
																			{categoery.title}
																		</li>
																	);
																})}
														</ul>
														<div className="select-form">
															Custom:
															<Form.Control
																type="text"
																placeholder="Fill in"
																onClick={this.addCustomCategory}
																onKeyDown={this.handleCustomCategory}
																name="customCategory"
																onChange={(e) => this.handleCustomChange(e)}
															/>
														</div>
														<div className="select-form mx-auto d-block text-center">
															<button class="theme-dark__btn submit-btn btn btn-primary" onClick={() => this.handleCatList()}>Close</button>
														</div>

													</div>
												)}
											</div>
										</Form.Group>

										<Form.Group className="col-md-8">
											<Form.Label>
												Title of your goal: <span>*</span>
											</Form.Label>
											<TextValidator
												placeholder="Fill in"
												name="title"
												onChange={this.handleChange}
												value={this.state.title}
												validators={["required"]}
												errorMessages={["Please fill title"]}
											/>
										</Form.Group>
									</Row>

									<Row className="timeline-row dates-timeline-row">
										<Form.Group className="col-md-4">
											<Form.Label>
												<h2>Dates:</h2>
												Goal start date: <span>*</span>
											</Form.Label>

											<div className="cust-datepicker">
												<DatePicker
													selected={this.state.startDate}
													/* minDate={new Date()} */
													onChange={(date) => this.setStartDate(date)}
													placeholderText="MM/DD/YYYY"
													/* dateFormat="mm/dd/yyyy" */
													className="datepicker form-control"
												/>
											</div>
										</Form.Group>

										<Form.Group className="col-md-4">
											<Form.Label>
												Goal completion date: <span>*</span>
											</Form.Label>
											<div className="cust-datepicker">
												<DatePicker
													selected={this.state.endDate}
													/* minDate={new Date()} */
													onChange={(date) => this.setEndDate(date)}
													placeholderText="MM/DD/YYYY"
													className="datepicker form-control"
												/>
											</div>
										</Form.Group>
									</Row>

									<Row className="timeline-row">
										<Form.Group className="col-md-12 tell-story">
											<Form.Label>
												<h2>Tell your story:</h2>
												Why are you committed?: <span>*</span>
											</Form.Label>
											<TextValidator
												placeholder="Tell your story..."
												name="commitment"
												onChange={this.handleChange}
												value={this.state.commitment}
												validators={["required"]}
												errorMessages={["Please fill commitment"]}
											/>
										</Form.Group>

										<Form.Group className="col-md-6">
											<Form.Label>Potential obstacles / roadblocks:</Form.Label>
											<Form.Control
												type="text"
												placeholder="Fill in"
												onChange={this.handleChange}
												value={this.state.obstacles}
												name="obstacles"
											/>
										</Form.Group>

										<Form.Group className="col-md-6">
											<Form.Label>How will you celebrate?:</Form.Label>
											<Form.Control
												type="text"
												placeholder="Fill in"
												onChange={this.handleChange}
												value={this.state.celebration}
												name="celebration"
											/>
										</Form.Group>
									</Row>

									{this.state.steps.length ? (
										<div className="goal-details-right col-lg-12">
											<h2>Steps</h2>
											{this.state.steps.map((step, index) => {
												return (
													<div className="counter-timeline" key={index}>
														<span className="counter">{index + 1}</span>
														<p>{step.text}</p>
														<p>
															<strong>Deadline:</strong>
															{step.deadline
																? moment(step.deadline).format("MMMM DD, YYYY")
																: "N/A"}
														</p>
													</div>
												);
											})}
										</div>
									) : (
										""
									)}

									<Row className="timeline-row counter-timeline">
										<Col md={12}>
											<h2>Update Steps:</h2>
											<Row className="link-btn-wrap">
												<div className="link-btn-wrap">
													<Button
														onClick={() => this.setState({ modalShow: true })}
														className="theme-dark-outline__btn"
													>
														Update Step
													</Button>

													<AddStep
														show={this.state.modalShow}
														onHide={modalClose}
														handleStep={this.handleStepValue}
														steps={this.state.steps}
													/>
												</div>
												<Button
													type="submit"
													onSubmit={(e) => e.preventDefault()}
													className="theme-dark__btn submit-btn"
												>
													Update Your Goal
												</Button>
											</Row>
										</Col>
									</Row>
								</ValidatorForm>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapState(state) {
	const { goals } = state;
	return { goals };
}

const actionCreators = {
	getDetails: goalActions.getDetails,
	getAllCategories: goalActions.getAllCategories,
	update: goalActions.update,
};

export default connect(mapState, actionCreators)(EditGoal);
