import { interestConstants } from '../constants';
import { interestService } from '../services';

export const interestActions = {
    getAll,
};

function getAll() {
    return dispatch => {
        dispatch(request());

        interestService.getAll()
            .then(
                interests => dispatch(success(interests.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: interestConstants.GETALL_REQUEST } }
    function success(interests) { return { type: interestConstants.GETALL_SUCCESS, interests } }
    function failure(error) { return { type: interestConstants.GETALL_FAILURE, error } }
}