import React from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

import TextValidator from "../TextValidator";
import { ValidatorForm } from "react-form-validator-core";

import { connect } from "react-redux";
import { gratitudeActions } from "../../actions";
import Header from "../Pages/Header";
import Sidebar from "../Pages/Sidebar";

class Gratitude extends React.Component {
  constructor() {
    super();
    this.state = {
      title: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.title) {
      this.props.save(this.state);
      this.setState({ title: "" });
    }
  }

  render() {
    return (
      <div className="page-wrap-main">
        <Header />

        <div className="wrapper-main">
          <Sidebar />

          <div className="page-content">
          <div className="card--white full-height gratutude-view">
            <div className="cust-heading-wrap">
              <h3 className="cust-heading cust-heading-view">Gratitude</h3>
              <Link to="/gratitude/gratitude-log" className="view__btn" >
                View log
                <span className="eye-ico"></span>
              </Link>
            </div>

            <div className="gratutude-post-block">
              <h2>What are you grateful for today?</h2>

              <ValidatorForm className="post-form" onSubmit={this.handleSubmit}>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>I am grateful for:</Form.Label>
                  <TextValidator
                    placeholder="Type here or put pen to paper"
                    name="title"
                    onChange={this.handleChange}
                    value={this.state.title}
                    validators={["required"]}
                    errorMessages={["Please fill about your grateful"]}
                    as="textarea"
                  />
                </Form.Group>

                <Form.Group className="text-right">
                  <Button
                    type="submit"
                    onSubmit={(e) => e.preventDefault()}
                    className="theme-dark__btn submit-btn"
                  >
                    Submit
                  </Button>
                </Form.Group>
              </ValidatorForm>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

const actionCreators = {
  save: gratitudeActions.save,
};

export default connect(null, actionCreators)(Gratitude);
