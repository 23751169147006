import React from 'react';
import { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({ Component, ...rest }) => {
    const [userRole,setUserRole] = useState(() =>
         (localStorage.getItem('user') !== '' && JSON.parse(localStorage.getItem('user')) )?
            JSON.parse(localStorage.getItem('user')).data.role : null
        )

    if( !userRole ){
       return  <Redirect to="/register" />
    }

    return(
        <Route
            {...rest}
            render={(props) =>
                <Component {...props} />
            }
        />
    )
}

export default PublicRoute;
