import { feelConstants } from '../constants';
import { feelService } from '../services';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const feelActions = {
    getAll,
    save,
};

function getAll() {
    return dispatch => {
        dispatch(request());

        feelService.getAll()
            .then(
                feels => dispatch(success(feels.data)),
                error => dispatch(failure(error.toString()))
            );
    };
  
    function request() { return { type: feelConstants.GETALL_REQUEST } }
    function success(feels) { return { type: feelConstants.GETALL_SUCCESS, feels } }
    function failure(error) { return { type: feelConstants.GETALL_FAILURE, error } }
}

function save(feel) {
    return dispatch => {
        dispatch(request(feel));

        feelService.save(feel)
            .then(
                feel => { 
                    dispatch(success());
                    toast.success(feel.message); 
                },
                error => {
                    dispatch(failure(error.toString()));
                    toast.error(error.toString()); 
                }
            );
    };

    function request(feel) { return { type: feelConstants.SAVE_REQUEST, feel } }
    function success(feel) { return { type: feelConstants.SAVE_SUCCESS, feel } }
    function failure(error) { return { type: feelConstants.SAVE_FAILURE, error } }
}