import React from "react";
import { Row, ProgressBar, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../Pages/Header";
import Sidebar from "../Pages/Sidebar";

import { connect } from "react-redux";
import { goalActions } from "../../actions";
import * as moment from "moment";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
var he = require('he');

class Home extends React.Component {

   constructor(props) {
    super(props);
    this.state = {
      disabled: false,
    };
    this.row = 0;
    this.rowperpage = 5;
    this.buttonText = "Load More";
    this.loadMore = this.loadMore.bind(this);
  }

   componentDidMount() {
    this.props.getGoals(this.row);
  }


  loadMore(e) {
    e.preventDefault();
    this.row += this.rowperpage;
    this.props.loadMore(this.row);
    this.buttonText = "Load More";
    if (this.row >= this.props.goals.totalNumberOfRows) {
         this.setState({ disabled: true });
    }
  }

  get getButtonText() {
    if (this.props.goals.loading) return 'Loading...';
    if (this.props.goals.error) return 'Error has occurred :(';
    return 'Load more'
  } 

  submit = (e) => {
    let id = e.target.getAttribute('id');
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete this goal.',
      buttons: [
        {
          label: 'Yes',
          className: 'theme-dark-outline__btn cust-cancel-btn btn btn-primary',
          onClick: () => this.deleteGoal(id)
        },
        {
          label: 'No',
          className: 'theme-dark__btn cust-save-btn btn btn-primary'
          /* onClick: () => alert('Click No') */
        }
      ]
    });
  };

  deleteGoal = (id) => {
    this.props.deleteGoal(id);
  }


  render() {
    const { goals } = this.props;
    const items = goals.items ? goals.items : "";
    return (
      <div className="page-wrap-main">
        <Header />

        <div className="wrapper-main">
          <Sidebar />

          <div className="page-content">
            <div className="card--white full-height goal-view">
              <div className="cust-heading-wrap">
                <h3 className="cust-heading cust-heading-view">your goals</h3>
                <Link className="theme-dark__btn" to="/goals/new-goal">
                  set new goal
                </Link>
              </div>

              <div className="goal-row-wrap">
               {items.length > 0 ? items &&
                  items.map((goal, index) => (
                <Row className="goal-row" key={index}>
                  <Col md={8} className="goal-left">
                    <img src={goal.category ? goal.category.image : ""} alt={goal.category ? goal.category.title : ""} />
                    <div className="goal-left-conent">
                      <Link to={`/goals/financial-goal/${goal._id}`}>
                        <h2>{ he.decode(goal.title) }</h2>
                      </Link>
                      <div className="progressbar cust-progressbar">
                        <ProgressBar animated now={Math.round(goal.avg)} />
                        <span>{Math.round(goal.avg)}% to goal</span>
                      </div>
                    </div>
                  </Col>
                  <Col md={4} className="goal-right">
                    <div className="goal-date">
                      <span>Achievement Date</span>
                      <span>{moment(goal.completionDate).format("MMMM DD, YYYY")}</span>
                    </div>
                    <Link className="eye-edit" to={`/goals/edit/${goal._id}`}></Link>
                    <Link className="ml-2 eye-ico" to={`/goals/financial-goal/${goal._id}`}></Link>
                    <button className="ml-2 delete-ico" id={goal._id} onClick={this.submit}></button>
                  </Col>
                </Row>
                )) : (<span className="no-data-found">You have not set your goals yet.</span>)}
              </div>
              <div className="link-btn-wrap">
                { this.row < goals.totalNumberOfRows  && goals.totalNumberOfRows > 5 &&
                    <button className="theme-dark-outline__btn load-more-btn" onClick={this.loadMore} disabled={this.state.disabled}>
                      {this.getButtonText}
                    </button>
                  }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { goals } = state;
  return { goals };
}

const actionCreators = {
  getGoals: goalActions.getAll,
  loadMore: goalActions.loadMore,
  deleteGoal: goalActions.deleteGoal,
};

export default connect(mapState, actionCreators)(Home); 
