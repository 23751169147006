import { valueConstants } from '../constants';
import { valueService } from '../services';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const valueActions = {
    getAll,
    save
};

function getAll() {
    return dispatch => {
        dispatch(request());

        valueService.getAll()
            .then(
                values => dispatch(success(values.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: valueConstants.GETALL_REQUEST } }
    function success(values) { return { type: valueConstants.GETALL_SUCCESS, values } }
    function failure(error) { return { type: valueConstants.GETALL_FAILURE, error } }
}

function save(value) {
    return dispatch => {
        dispatch(request(value));

        valueService.save(value)
            .then(
                value => { 
                    dispatch(success());
                    //toast.success(value.message); 
                },
                error => {
                    dispatch(failure(error.toString()));
                    toast.error(error.toString()); 
                }
            );
    };

    function request(value) { return { type: valueConstants.SAVE_REQUEST, value } }
    function success(value) { return { type: valueConstants.SAVE_SUCCESS, value } }
    function failure(error) { return { type: valueConstants.SAVE_FAILURE, error } }
}