import { goalConstants } from '../constants';
import { goalService } from '../services';
import { history } from '../helpers';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const goalActions = {
    getAllCategories,
    getAll,
    save,
    getDetails,
    getGoalSteps,
    updateGoalSteps,
    loadMore,
    deleteGoal,
    update
};

function getAll(row) {
    return dispatch => {
        dispatch(request(row));

        goalService.getAll(row)
            .then(
                goals => dispatch(success(goals)),
                error => dispatch(failure(error.toString()))
            );
    };
  
    function request() { return { type: goalConstants.GETALL_REQUEST } }
    function success(goals) { return { type: goalConstants.GETALL_SUCCESS, goals } }
    function failure(error) { return { type: goalConstants.GETALL_FAILURE, error } }
}

function save(goal) {
    return dispatch => {
        dispatch(request(goal));

        goalService.save(goal)
            .then(
                goal => { 
                    dispatch(success());
                    toast.success(goal.message); 
                    history.push('/goals');
                },
                error => {
                    dispatch(failure(error.toString()));
                    toast.error(error.toString()); 
                }
            );
    };

    function request(goal) { return { type: goalConstants.SAVE_REQUEST, goal } }
    function success(goal) { return { type: goalConstants.SAVE_SUCCESS, goal } }
    function failure(error) { return { type: goalConstants.SAVE_FAILURE, error } }
}

function getAllCategories() {
    return dispatch => {
        dispatch(request());

        goalService.getAllCategories()
            .then(
                categories => dispatch(success(categories.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: goalConstants.GETALL_CATEGORY_REQUEST } }
    function success(categories) { return { type: goalConstants.GETALL_CATEGORY_SUCCESS, categories } }
    function failure(error) { return { type: goalConstants.GETALL_CATEGORY_FAILURE, error } }
}

function getDetails(id) {
    return dispatch => {
        dispatch(request());

        goalService.getDetails(id)
            .then(
                goal => dispatch(success(goal.data)),
                error => dispatch(failure(error.toString()))
            );
    };
  
    function request() { return { type: goalConstants.GETDETAILS_REQUEST } }
    function success(goal) { return { type: goalConstants.GETDETAILS_SUCCESS, goal } }
    function failure(error) { return { type: goalConstants.GETDETAILS_FAILURE, error } }
}

function getGoalSteps(id) {
    return dispatch => {
        dispatch(request());

        goalService.getGoalSteps(id)
            .then(
                steps => dispatch(success(steps)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: goalConstants.GET_STEPS_REQUEST } }
    function success(steps) { return { type: goalConstants.GET_STEPS_SUCCESS, steps } }
    function failure(error) { return { type: goalConstants.GET_STEPS_FAILURE, error } }
}

function updateGoalSteps(id) {
    return dispatch => {
        dispatch(request());

        goalService.updateGoalSteps(id)
           .then(
                step => { 
                    dispatch(success(step.data));
                    toast.success(step.message); 
                },
                error => {
                    dispatch(failure(error.toString()));
                    toast.error(error.toString()); 
                }
            );
    };

    function request() { return { type: goalConstants.UPDATE_STEPS_REQUEST } }
    function success(step) { return { type: goalConstants.UPDATE_STEP_SUCCESS, step } }
    function failure(error) { return { type: goalConstants.UPDATE_STEP_FAILURE, error } }
}

function loadMore(row) {
    return dispatch => {
        dispatch(request(row));

        goalService.getAll(row)
            .then(
                goals => dispatch(success(goals)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: goalConstants.LOAD_MORE_REQUEST } }
    function success(goals) { return { type: goalConstants.LOAD_MORE_SUCCESS, goals } }
    function failure(error) { return { type: goalConstants.LOAD_MORE_FAILURE, error } }
}

function deleteGoal(id) {
    return dispatch => {
        dispatch(request(id));

        goalService.deleteGoal(id)
            .then(
                goal => { dispatch(success(id));  toast.success(goal.message); },
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: goalConstants.DELETE_GOAL_REQUEST, id } }
    function success(id) { return { type: goalConstants.DELETE_GOAL_SUCCESS, id } }
    function failure(id, error) { return { type: goalConstants.DELETE_GOAL_FAILURE, id, error } }
}

function update(id, data) {
    return dispatch => {
        dispatch(request());

        goalService.update(id, data)
           .then(
                goal => { 
                    dispatch(success(goal.data));
                    toast.success(goal.message); 
                    history.push('/goals');
                },
                error => {
                    dispatch(failure(error.toString()));
                    toast.error(error.toString()); 
                }
            );
    };

    function request() { return { type: goalConstants.UPDATE_REQUEST } }
    function success(goal) { return { type: goalConstants.UPDATE_SUCCESS, goal } }
    function failure(error) { return { type: goalConstants.UPDATE_FAILURE, error } }
}