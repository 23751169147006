import React, { Component } from "react";
import { Form, Spinner } from "react-bootstrap";
import Logo from "../../images/logo.png";
import "../../../node_modules/font-awesome/css/font-awesome.css";
import TextValidator from "../TextValidator";
import { ValidatorForm } from "react-form-validator-core";
import { connect } from "react-redux";
import { userActions, alertActions } from "../../actions";
import HandGesture from "../../images/add-gratitude.png";
import { Link } from "react-router-dom";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: "",
      },
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ submitted: true });
    const { user } = this.state;
    if (user.email) {
      this.props.forgotPassword(user);
    }
  }
  render() {
    const { forgating } = this.props;
    return (
      <div className="wrap-login-view forgot-ps-view">
    <div className="main main-sign-process">
          <div className="sign-container a-container" id="a-container">
            <div className="wrap-sign-form-view">
              <img src={Logo} alt="" className="logo-sign" />
              <h3 className="cust-heading-center">Forget Password</h3>

              <ValidatorForm className="sign-form" onSubmit={this.handleSubmit}>
                <Form.Group controlId="email">
                  <Form.Label>Email:</Form.Label>
                  <TextValidator
                    type="email"
                    placeholder="Enter Email"
                    name="email"
                    onChange={this.handleChange}
                    value={this.state.user.email}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "The email field is required",
                      "Email is not valid.",
                    ]}
                  />
                </Form.Group>

                <Form.Group className="submit-block">
                  <button
                    type="submit"
                    className="theme-dark__btn submit-btn btn btn-primary"
                    disabled={forgating}
                  >
                    Submit
                    {forgating && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </Form.Group>
              </ValidatorForm>
              <span className="forget-link">
               {/*  Don't have an account? <Link to="/register">Sign up here.</Link> */}
              </span>
            </div>
          </div>

          {/* switch containers */}
          <div className="switch" id="switch-cnt">
            <div className="switch__circle"></div>
            <div className="switch__circle switch__circle--t"></div>
            <div className="switch__container" id="switch-c1">
              <div className="splash-view">
                <img src={HandGesture} alt="" className="switch-img" />
                <h3 className="cust-heading-center cust-heading-white">
                  Forgot Password
                </h3>
                <span>Enter your email and reset your password</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  const { forgating } = state.forgot;
  return { alert, forgating };
}

const actionCreators = {
  forgotPassword: userActions.forgotPassword,
  clearAlerts: alertActions.clear,
};

export default connect(mapState, actionCreators)(ForgotPassword);