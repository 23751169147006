import React from "react";
import { Button, Form } from 'react-bootstrap';
import $ from "jquery";

$(document).on('submit', '#mc-embedded-subscribe-form', function (event) {
      try {
        var $form = $(this);
        event.preventDefault();
        register($form);
      } catch (error) {}
    });

function register($form) {
      $('#mc-embedded-subscribe').val('Sending...');
      $('#mc-embedded-subscribe').attr('disabled','disabled');
      $.ajax({
        type: "GET",
        url: $form.attr('action'),
        data: $form.serialize(),
        cache: false,
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        error: function (err) {
          console.log('error')
        },
        success: function (data) {
          if (data.result != "success") {
            $($form).find("div#subscribe-result").html("<p class='error-message'>"+data.msg+"</p>"); 
            setTimeout(function() {  $($form).find("div#subscribe-result p").text(""); }, 9000);
            $("#mce-EMAIL").val("");
            $('#mc-embedded-subscribe').val('Send');
            $('#mc-embedded-subscribe').removeAttr('disabled');
          } else {
            $($form).find("div#subscribe-result").html("<p class='success-message'>Thank you for subscribing us!</p>"); 
            setTimeout(function() {  $($form).find("div#subscribe-result p").text(""); }, 9000);
            $("#mce-EMAIL").val("");
            $('#mc-embedded-subscribe').val('Send');
            $('#mc-embedded-subscribe').removeAttr('disabled');
          }
        }
      });
    }

class Newsletter extends React.Component {
    render() {
        return (
                <div className="newsletter-block">
                    <div className="container-min">
                        <h2>subscribe to our newsletter</h2>
                        <div className="newsletter-form">
                                <div className="newsletter-fields">
                                    <div id="mc_embed_signup">
                                        <form action="https://mygoals.us6.list-manage.com/subscribe/post-json?u=f8977cd6c27f2d4181c337053&amp;id=8d6fc159d7&c=?" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate">
                                            <div id="mc_embed_signup_scroll">
                                                <div className="wrap-newsletter">   
                                                    <div className="mc-field-group">
                                                        <input type="email" name="EMAIL" placeholder="Email" className="required email form-control" id="mce-EMAIL" required />
                                                    </div>
                                                    <div className="clear"><input type="submit" value="Submit" name="subscribe" id="mc-embedded-subscribe" className="button theme-dark__btn submit-btn btn btn-primary" /></div>
                                                </div>
                                                <div id="mce-responses" className="clear">
                                                    <div className="response" id="mce-error-response" ></div>
                                                    <div className="response" id="mce-success-response" ></div>
                                                </div>    
                                            </div>
                                            <div id="subscribe-result"></div>
                                        </form>
                                    </div>
                                </div>
                                <Form.Text>
                                    No fluff. No spam. Occasional listicles. Unsubscribe anytime.
                                </Form.Text>
                        </div>
                    </div>
                </div>
        );
    }
}

export default Newsletter;