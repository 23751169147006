import React, { Component } from 'react';
import MobileLogo from '../images/mobilelogo.png';
import HandIllus from '../images/hands-illus.svg';

class MobileView extends Component {
    
    render() {
        return (
            <div className="media-wrap">
                <section className="media-header media-container">
                    <a href="https://mygoals.co/" className="media-logo"> <img src={MobileLogo} alt="My goals" /> </a>
                    <a href="https://mygoals.co/#contact" className="cust-btn">get in touch</a>
                </section>

                <section className="media-content">
                    <div className="media-container">
                        <h2 className="cust-heading-center">Hello, Friend</h2>
                        <h3>We love that you’re with us to work on your goals, wellness, and personal development.</h3>
                        <p>Currently, our app only works on desktops, laptops and tablets. Our mobile apps for ios and android will come soon.
                        </p>
                        <span>Thank you!</span>
                    </div>
                </section>


                <section className="media-footer">
                    <div className="media-container">
                        <img src={HandIllus} className="footer-feature-img" alt="" />
                        <span>&copy; Copyright 2021. All rights reserved.</span>
                        <ul>
                            <li><a href="https://mygoals.co/wp-content/uploads/2020/05/Terms.pdf" target="_blank" rel="noreferrer">Terms and Conditions</a></li>
                            <li><a href="https://mygoals.co/wp-content/uploads/2020/05/GDPR.pdf" target="_blank" rel="noreferrer">GDPR</a></li>
                            <li><a href="https://mygoals.co/wp-content/uploads/2020/05/Privacy-Policy-1.pdf" target="_blank" rel="noreferrer">Privacy Policy</a></li>
                        </ul>
                    </div>
                </section>
            </div>
        );
    }
}
export default MobileView;