import { gratitudeConstants } from '../constants';
import { gratitudeService } from '../services';
import { history } from '../helpers';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const gratitudeActions = {
    getAll,
    save,
    loadMore,
    deleteGratitude
};

function getAll(row) {
    return dispatch => {
        dispatch(request(row));

        gratitudeService.getAll(row)
            .then(
                gratitudes => dispatch(success(gratitudes)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: gratitudeConstants.GETALL_REQUEST } }
    function success(gratitudes) { return { type: gratitudeConstants.GETALL_SUCCESS, gratitudes } }
    function failure(error) { return { type: gratitudeConstants.GETALL_FAILURE, error } }
}

function save(gratitude) {
    return dispatch => {
        dispatch(request(gratitude));

        gratitudeService.save(gratitude)
            .then(
                gratitude => { 
                    dispatch(success());
                    toast.success(gratitude.message); 
                    history.push('/gratitude/gratitude-log');
                },
                error => {
                    dispatch(failure(error.toString()));
                    toast.error(error.toString()); 
                }
            );
    };

    function request(gratitude) { return { type: gratitudeConstants.SAVE_REQUEST, gratitude } }
    function success(gratitude) { return { type: gratitudeConstants.SAVE_SUCCESS, gratitude } }
    function failure(error) { return { type: gratitudeConstants.SAVE_FAILURE, error } }
}

function loadMore(row) {
    return dispatch => {
        dispatch(request(row));

        gratitudeService.getAll(row)
            .then(
                gratitudes => dispatch(success(gratitudes)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: gratitudeConstants.LOAD_MORE_REQUEST } }
    function success(gratitudes) { return { type: gratitudeConstants.LOAD_MORE_SUCCESS, gratitudes } }
    function failure(error) { return { type: gratitudeConstants.LOAD_MORE_FAILURE, error } }
}

function deleteGratitude(id) {
    return dispatch => {
        dispatch(request(id));

        gratitudeService.deleteGratitude(id)
            .then(
                gratitude => { dispatch(success(id));  toast.success(gratitude.message); },
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: gratitudeConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: gratitudeConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: gratitudeConstants.DELETE_FAILURE, id, error } }
}