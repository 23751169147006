import { authHeader } from '../helpers';
export const gratitudeService = {
    getAll,
    save,
    deleteGratitude,
};

function getAll(row) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/gratitudes?row=${row}`, requestOptions).then(handleResponse);
}

function save(gratitude) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(gratitude)
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/gratitudes`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function deleteGratitude(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/gratitudes/${id}`, requestOptions).then(handleResponse);
}