import { coachingConstants } from '../constants';

export function coaching(state = {}, action) {
  switch (action.type) {
    case coachingConstants.GETALL_REQUEST:
      return {
         ...state,
        loading: true
      };
    case coachingConstants.GETALL_SUCCESS:
      return {
        ...state,
        error: null,
        items: action.contents.res.data
      };
    case coachingConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };
    
     case coachingConstants.GETROW_REQUEST:
      return {
         ...state,
        loading: true
      };
    case coachingConstants.GETROW_SUCCESS:
      return {
        ...state,
        error: null,
        items: action.contents.res.data
      };
    case coachingConstants.GETROW_FAILURE:
      return { 
        error: action.error
      };


    case coachingConstants.SEND_REQUEST:
      return {
         ...state,
        loading: true
      };
    case coachingConstants.SEND_SUCCESS:
      return {
        ...state,
        error: null,
        items: action.contents.res.data
      };
    case coachingConstants.SEND_FAILURE:
      return { 
        error: action.error
      };

    default:
      return state
  }
}