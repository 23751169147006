const axios = require('axios');
export const contentService = {
    getAll,
    getDetails,
    getAllRelatedContents,
    getAllWithoutLimit
};

function getAll(row) {
    return axios.get(`https://mygoals.co/wp-json/wp/v2/posts?offset=${row}&per_page=3&orderbby=date&order=desc&_embed`).then(res => {
            return { 
                res: res,
                wpTotal: parseFloat(res.headers['x-wp-total'])
            };
        });
}

function getDetails(id) {
    return axios.get(`https://mygoals.co/wp-json/wp/v2/posts/${id}`).then();
}

function getAllRelatedContents(row) {
    return axios.get(`https://mygoals.co/wp-json/wp/v2/posts?page=1&per_page=3&orderbby=date&order=desc&categories=${row}&_embed`).then();
}

function getAllWithoutLimit() {
    return axios.get(`https://mygoals.co/wp-json/wp/v2/posts?orderbby=date&order=desc&_embed`).then();
}