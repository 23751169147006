import { contentConstants } from '../constants';
import { contentService } from '../services';
import 'react-toastify/dist/ReactToastify.css';

export const contentActions = {
    getAll,
    getDetails,
    getPostCategories,
    getAllRelatedContents,
    loadMore,
    getAllWithoutLimit
};

function getAll(row) {
    return dispatch => {
        dispatch(request(row));

        contentService.getAll(row)
            .then(
                contents => dispatch(success(contents)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: contentConstants.GETALL_REQUEST } }
    function success(contents) { return { type: contentConstants.GETALL_SUCCESS, contents } }
    function failure(error) { return { type: contentConstants.GETALL_FAILURE, error } }
}

function getDetails(id) {
    return dispatch => {
        dispatch(request(id));

        contentService.getDetails(id)
            .then(
                contents => dispatch(success(contents)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: contentConstants.GETALL_DETAILS_REQUEST } }
    function success(contents) { return { type: contentConstants.GETALL_DETAILS_SUCCESS, contents } }
    function failure(error) { return { type: contentConstants.GETALL_DETAILS_FAILURE, error } }
}

function getPostCategories(id) {
    return dispatch => {
        dispatch(request(id));

        contentService.getPostCategories(id)
            .then(
                contents => dispatch(success(contents)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: contentConstants.GET_CATEGORIES_REQUEST } }
    function success(contents) { return { type: contentConstants.GET_CATEGORIES_SUCCESS, contents } }
    function failure(error) { return { type: contentConstants.GET_CATEGORIES_FAILURE, error } }
}

function getAllRelatedContents(row) {
    return dispatch => {
        dispatch(request(row));

        contentService.getAllRelatedContents(row)
            .then(
                contents => dispatch(success(contents)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: contentConstants.GETALL_RELATED_REQUEST } }
    function success(contents) { return { type: contentConstants.GETALL_RELATED_SUCCESS, contents } }
    function failure(error) { return { type: contentConstants.GETALL_RELATED_FAILURE, error } }
}

function loadMore(row) {
    return dispatch => {
        dispatch(request(row));

        contentService.getAll(row)
            .then(
                contents => dispatch(success(contents)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: contentConstants.LOAD_MORE_REQUEST } }
    function success(contents) { return { type: contentConstants.LOAD_MORE_SUCCESS, contents } }
    function failure(error) { return { type: contentConstants.LOAD_MORE_FAILURE, error } }
}

function getAllWithoutLimit() {
    return dispatch => {
        dispatch(request());

        contentService.getAllWithoutLimit()
            .then(
                contents => dispatch(success(contents)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: contentConstants.GETALL_WITHOUT_LIMIT_REQUEST } }
    function success(contents) { return { type: contentConstants.GETALL_WITHOUT_LIMIT_SUCCESS, contents } }
    function failure(error) { return { type: contentConstants.GETALL_WITHOUT_LIMIT_FAILURE, error } }
}