import { userfeelConstants } from '../constants';

export function userfeels(state = [], action) {
  switch (action.type) {
    case userfeelConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case userfeelConstants.GETALL_SUCCESS:
      return {
        loading: false,
        items: action.userfeels.data,
        totalNumberOfRows: action.userfeels.totalNumberOfRows
      };
    case userfeelConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };
    case userfeelConstants.LOAD_MORE_REQUEST:
      return {
         ...state,
        loading: true
      };
    case userfeelConstants.LOAD_MORE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: [ ...(state.items || []), ...action.userfeel.data ]
      };
      case userfeelConstants.LOAD_MORE_FAILURE:
        return { 
          error: action.error
        };
       case userfeelConstants.DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
     case userfeelConstants.DELETE_SUCCESS:
      return {
      ...state,
      loading: false,
      items: 
      [
          ...state.items.filter(value => value._id !== action.id)
      ]
    };
    case userfeelConstants.DELETE_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state
  }
}