import React, { useEffect, useState } from "react";
import { ValidatorForm } from 'react-form-validator-core';
import { Button, Form, Spinner, Modal } from 'react-bootstrap';
import TextValidator from '../../TextValidator';
import { connect } from 'react-redux';
import { modalAction, userActions } from "../../../actions";
import { toast } from "react-toastify";
import { userService } from "../../../services";

const Create = (props) => {
    
  const [formInputs,setFormInputs] = useState([])
  const [registering,setRegistering] = useState(false);
  const [roleId,setRoleId ] =useState("2");

  

  const handleClose = () => {
        props.hideShowAddUserModal(false);
        props.hidepopupmodal(false,true);
        
  }

  
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormInputs({ ...formInputs,[name]:value });
    
  }
  
  const handleSubmit = async () => {
    setRegistering(true)
    if( formInputs?.firstName && formInputs?.lastName && formInputs?.email  ){
        formInputs.role = roleId
        formInputs.password = generatePassword(10)
        
        let userData = await userService.register(formInputs).then((user) => {
            toast.success(user.message)
        } ).catch( (error) => {
            toast.error(error)
        });
    
        props.hideShowAddUserModal(false);
        props.hidepopupmodal(false,true);
    }
    setRegistering(false)
    props.hideShowAddUserModal(false);

  }

    const generatePassword = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
        }
        return result;
    }

  return (
        <Modal show={props.show} onHide={(e) => handleClose(false) } centered size="xl" className="user_create-modal">
            <Modal.Header closeButton className="border-0">
            </Modal.Header>
            <Modal.Body>
                <div className="cust-heading-wrap text-center pb-3">
                        <h3 className="cust-heading cust-heading-view text-center w-100 request-form-heading">Add New User</h3>
                </div>
                <ValidatorForm className="sign-form d-flex flex-wrap pt-3" id="sign-form" onSubmit={handleSubmit}>
                    <Form.Group controlId="email">
                        <Form.Label>Email:</Form.Label>
                        <TextValidator type="email" placeholder="Enter email" name="email"
                            onChange={(e) =>  handleChange(e) }
                            value={formInputs.email || ''}
                            validators={['required', 'isEmail']}
                            errorMessages={['The email field is required', 'Email is not valid.']} />
                    </Form.Group>
                    <Form.Group controlId="first-name">
                        <Form.Label>First name:</Form.Label>
                        <TextValidator type="text" placeholder="Enter name" name="firstName"
                            onChange={(e) =>  handleChange(e) }
                            value={formInputs.firstName || ''}
                            validators={['required', 'maxStringLength:30']}
                            errorMessages={['The first name field is required', 'You can enter max 30 character in this field']} />
                    </Form.Group>

                    <Form.Group controlId="last-name">
                        <Form.Label>last name:</Form.Label>
                        <TextValidator type="text" placeholder="Enter last name" name="lastName"
                            onChange={(e) =>  handleChange(e) }
                            value={formInputs.lastName || ''}
                            validators={['required', 'maxStringLength:30']}
                            errorMessages={['The last name field is required', 'You can enter max 30 character in this field']} />
                    </Form.Group>

                    <Form.Group className="submit-block text-left pt-3">
                       <Form.Label>User Type</Form.Label>
                        <div className="pt-2">
                           <label className="mr-5" htmlFor="admin" onChange={(e) => setRoleId("1") } >
                                <input type="radio" value={"1"} id="admin" name="role" checked={ roleId === "1" && 'checked' } onChange={(e) => setRoleId("1") } />
                                <span className="pl-1" htmlFor="admin"  >admin</span>
                            </label>
                            <lable htmlFor="user" onChange={(e) => setRoleId("2") } >
                                <input type="radio" id="user" value={"2"} name="role" checked={ roleId === "2"  && 'checked' } onChange={(e) => setRoleId("2") } />
                                <span className="pl-1" htmlFor="admin"  >user</span>
                            </lable>
                        </div>
                    </Form.Group>

                    <Form.Group className="submit-block text-right pt-3">
                        <button type="submit" className="theme-dark__btn submit-btn btn btn-primary w-100" disabled={registering}>SEND INVITE {registering &&
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        />}</button>
                    </Form.Group>
                </ValidatorForm>
            </Modal.Body>
        </Modal>
    );


}

const MapStateToProps = state => ({
    showAddUserModal: state.modalReduces.showAddUserModal,
    registering:state.registration,
    submitted: state.registration,
})

const MapDispatchToProps = dispatch => ({
    hideShowAddUserModal : (data) => dispatch(modalAction(data)),
    register: userActions.register,
})



export default connect(MapStateToProps,MapDispatchToProps)(Create);