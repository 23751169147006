import React, { Component } from 'react'
import Header from '../Pages/Header';
import Profile from '../Pages/Profile';

class ProfileWithHeader extends Component {
    render() {
        return (
            <div className="page-wrap-main header-content-layout">
                <Header />

                <div className="wrapper-main">
                    <div className="page-content">
                        <Profile />
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfileWithHeader