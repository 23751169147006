import React from "react";
import { Col, Form, Button } from "react-bootstrap";
import UserAvatar from "../../images/profile.svg";
import AddValues from "../Pages/Modals/AddValues";
import AddInterestPassion from "../Pages/Modals/AddInterestPassion";
import ProfileUpload from "../Pages/Modals/ProfileUpload";

import TextValidator from '../TextValidator';
import { ValidatorForm } from 'react-form-validator-core';

import { connect } from 'react-redux';
import { userActions } from '../../actions';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";

class Profile extends React.Component {

    constructor(props) {
        console.log( props.user.data );
        super(props);
        this.editEventRef = React.createRef();
        this.editEventInterestRef = React.createRef();
        this.state = {
                        values: props.user.data.values ? props.user.data.values : [],
                        interests: props.user.data.interests ? props.user.data.interests : [],
                        profileImage: props.user.data.avatar ? props.user.data.avatar : UserAvatar,
                        role: props.user.data.role?props.user.data.role:"2",
                        uploadedFile:null,
                        firstNameDisable:true,
                        lastNameDisable:true,
                        purposeDisable:true,
                        interestModalShow: false,
                        valueModalShow: false,
                        profileModalShow: false,
                        firstName: '',
                        lastName: '',
                        purpose: '',
                        email: '',
                     };
    }

    componentDidMount() {
        const { firstName, lastName, purpose, email } = this.props.user.data;
        this.setState({firstName, lastName, purpose, email});
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            ...this.state,
            [name]: value
        });
    }
    handleSubmit = (event) => {
        const user = {
            "firstName": this.state.firstName,
            "lastName": this.state.lastName,
            "purpose": this.state.purpose,
        }
        if (this.state.firstName && this.state.lastName) {
            this.props.update(user);
            toast.success("Profile data updated successfully");
        }
    }
    deleteValue = (id) => {
        this.setState(prevState => ({
            values: prevState.values.filter(el => el !== id )
        }));
        this.props.deleteValue(id._id);
       /*  this.editEventRef.current.submitSettings(id); */
    }
    deleteInterest = (id) => {
        this.setState(prevState => ({
            interests: prevState.interests.filter(el => el !== id )
        }));
        this.props.deleteInterest(id._id);
        // this.editEventInterestRef.current.submitInterestSettings(id);
    }
    handleProfileValue = (val) => {
        this.setState({ uploadedFile: val })
        this.setState({ profileModalShow: false })
        this.setState({ profileImage: URL.createObjectURL(val) })
    }
    /* updateValues = (newValues) => {
        this.setState({values: newValues})
    } */
    enableFirstName = () => {
        this.setState({firstNameDisable:!this.state.firstNameDisable});
    }
    enableLastName = () => {
        this.setState({lastNameDisable:!this.state.lastNameDisable});
    }
    enablepurpose = () => {
        this.setState({purposeDisable:!this.state.purposeDisable});
    }

    render() {
        const { user } = this.props;
        return (
            <div className="card--white full-height profile-view">
                <h3 className="cust-heading-center">My profile</h3>
                <ValidatorForm className="row profile-form" id="profile-form" onSubmit={this.handleSubmit}>
                    <Col className="profile-edit-block">
                        <div className="row">
                            <Form.Group className="col-md-3">
                                <div className="user-profile-edit-card" >
                                    <img src={user.data.avatar ? user.data.avatar : UserAvatar} alt="" />
                                    <div>
                                        <span>Profile Picture</span>
                                        <Button onClick={() => this.setState({ profileModalShow: true })} className="edit-ico override-btn"></Button>
                                        <ProfileUpload
                                            show={this.state.profileModalShow}
                                            onHide={() => this.setState({ profileModalShow: false })}
                                            /* handleprofilevalue={this.handleProfileValue} */
                                        />
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group controlId="firstname" className="col-md-3">
                                <Form.Label>First Name:</Form.Label>
                                <div className="profile-form-inputs">
                                    <TextValidator type="text" placeholder="Elliot" name="firstName"
                                        onChange={this.handleChange}
                                        value={this.state.firstName}
                                        validators={['required']}
                                        errorMessages={['The first name field is required']}
                                        disabled={this.state.firstNameDisable}
                                    />
                                    <span className={this.state.firstNameDisable ? "edit-ico": "tick-ico" } onClick={this.enableFirstName}></span>
                                </div>
                            </Form.Group>

                            <Form.Group controlId="lastname" className="col-md-3">
                                <Form.Label>last Name:</Form.Label>
                                <div className="profile-form-inputs">
                                     <TextValidator type="text" placeholder="MacNay" name="lastName"
                                        onChange={this.handleChange}
                                        value={this.state.lastName}
                                        validators={['required']}
                                        errorMessages={['The last name field is required']}
                                        disabled={this.state.lastNameDisable}
                                    />
                                    <span className={this.state.lastNameDisable ? "edit-ico": "tick-ico" } onClick={this.enableLastName}></span>
                                </div>
                            </Form.Group>

                            <Form.Group controlId="useremail" className="col-md-3">
                                <Form.Label>Email:</Form.Label>
                                <div className="profile-form-inputs">
                                    <TextValidator type="email" placeholder="elliot@mygoals.co" name="email"
                                        onChange={this.handleChange}
                                        value={user.data.email}
                                        validators={['required', 'isEmail']}
                                        errorMessages={['The email field is required', 'Email is not valid.']} readOnly />
                                    {/* <span className="edit-ico"></span> */}
                                </div>
                            </Form.Group>

                            {/* <Form.Group className="text-right">
                                <Button type="submit" onSubmit={e => e.preventDefault()} className="theme-dark__btn submit-btn">
                                    Submit
                                </Button>
                            </Form.Group> */}
                        </div>
                    </Col>

                    <Col md={12} className="user-profile-cols purpose-cnt">
                        <Form.Group controlId="purpose">
                              <Form.Label>Purpose:</Form.Label>
                              <Form.Control type="text" name="purpose" value={this.state.purpose} placeholder="" onChange={this.handleChange} disabled={this.state.purposeDisable} />
                              <span className={this.state.purposeDisable ? "edit-ico": "tick-ico" } onClick={this.enablepurpose}></span>
                        </Form.Group>
                    </Col>

                    <Col md={12} className="user-profile-cols tags-block">
                        <Form.Group controlId="purpose">
                            <Form.Label>Values:</Form.Label>
                            <ul className="tags">
                               {
                                    user.data.values.map(el=>
                                        <li key={el._id}>{el.title} <span className="remove" onClick={this.deleteValue.bind(this, el)}></span></li>
                                    )
                                }
                            </ul>
                            <Button onClick={() => this.setState({ valueModalShow: true })} className="cust-plus__btn theme-dark__btn">Add</Button>
                            { this.state.valueModalShow && <AddValues
                                show={this.state.valueModalShow}
                                onHide={() => this.setState({ valueModalShow: false })}
                                ref={this.editEventRef}
                                /* updateValues={this.updateValues} */
                                /* selectedValues={this.state.values} */
                            /> }
                        </Form.Group>
                    </Col>

                    <Col md={12} className="user-profile-cols tags-block interests-block">
                        <Form.Group controlId="values">
                            <Form.Label>Interests and passions:</Form.Label>
                            <ul className="tags">
                                {
                                    user.data.interests.map(el=>
                                        <li key={el._id}>{el.title} <span className="remove" onClick={this.deleteInterest.bind(this, el)}></span></li>
                                    )
                                }
                            </ul>
                            <Button onClick={() => this.setState({ interestModalShow: true })} className="cust-plus__btn theme-dark__btn">Add</Button>
                            { this.state.interestModalShow && <AddInterestPassion
                                show={this.state.interestModalShow}
                                onHide={() => this.setState({ interestModalShow: false })}
                                ref={this.editEventInterestRef}
                            /> }
                        </Form.Group>
                    </Col>

                    <Col md={12} className="user-profile-cols profile-save-col">
                        <Link className="theme-dark-outline__btn cust-cancel-btn" to="/profile">Cancel</Link>
                        <Button className="theme-dark__btn cust-save-btn" type="submit">Save</Button>
                    </Col>
                </ValidatorForm>
            </div>
        );
    }
}

function mapState(state) {
	const { user } = state;
    return { user };
}

const actionCreators = {
    update: userActions.update,
    deleteValue: userActions.deleteValue,
    deleteInterest: userActions.deleteInterest,
}

export default connect(mapState, actionCreators)(Profile); 