import { authHeader } from '../helpers';

export const valueService = {
    getAll,
    save
};

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/values`, requestOptions).then(handleResponse);
}

function save(value) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(value)
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/values`, requestOptions).then(handleResponse).then(data => {
            let existing = JSON.parse(localStorage.getItem('user'));
            let newData = {
                "status": existing.status,
                "message": existing.message,
                data: {
                    "firstName": data.data.firstName,
                    "lastName": data.data.lastName,
                    "token": existing.data.token,
                    "values": data.data.values,
                    "purpose": data.data.purpose,
                    "interests": data.data.interests,
                    "email": data.data.email
                }
            }
            localStorage.setItem('user', JSON.stringify(newData));
            return data;
    });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data.data && data.data[0].msg) || data.message;
            return Promise.reject(error);
        }

        return data;
    });
}