import { userConstants } from "../constants";

let userData = JSON.parse(localStorage.getItem('user'));
const initialState = userData ? userData : {};

export function user(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_SUCCESS:
      return action.user;
     case userConstants.UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.UPDATE_SUCCESS:
       return {
        ...state,
        data: {
          ...state.data,
          firstName:action.user.data.firstName,
          lastName:action.user.data.lastName,
          purpose:action.user.data.purpose,
        }
      }
    case userConstants.UPDATE_FAILURE:
      return {
        error: action.error,
      };
    case userConstants.UPDATE_AVATAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.UPDATE_AVATAR_SUCCESS:
       let avatar = action.data.data.avatar;
       return {
        ...state,
        data: {
          ...state.data,
            avatar
        }
      }
    case userConstants.UPDATE_AVATAR_FAILURE:
      return {
        error: action.error,
      };
    case userConstants.UPDATE_VALUE_REQUEST:
      return {
        ...state,
        loading: true,
      };
     case userConstants.UPDATE_VALUE_SUCCESS:
       let values = action.data;
       return {
        ...state,
        data: {
          ...state.data,
            values
        }
      }
    case userConstants.UPDATE_VALUE_FAILURE:
      return {
        error: action.error,
      };
    case userConstants.UPDATE_INTEREST_REQUEST:
      return {
        ...state,
        loading: true,
      };
     case userConstants.UPDATE_INTEREST_SUCCESS:
       let interests = action.data;
       return {
        ...state,
        data: {
          ...state.data,
            interests
        }
      }
    case userConstants.UPDATE_INTEREST_FAILURE:
      return {
        error: action.error,
      };
    case userConstants.DELETE_VALUE_REQUEST:
      return {
        ...state,
        loading: true,
      };
     case userConstants.DELETE_VALUE_SUCCESS:
      return {
      ...state,
      data: {
          ...state.data,
          values: [
            ...state.data.values.filter(value => value._id !== action.id)
          ]
      },
    };
    case userConstants.DELETE_VALUE_FAILURE:
      return {
        error: action.error,
      };
     case userConstants.DELETE_INTEREST_REQUEST:
      return {
        ...state,
        loading: true,
      };
     case userConstants.DELETE_INTEREST_SUCCESS:
      return {
      ...state,
      data: {
          ...state.data,
          interests: [
            ...state.data.interests.filter(value => value._id !== action.id)
          ]
      },
    };
    case userConstants.DELETE_INTEREST_FAILURE:
      return {
        error: action.error,
      };
    case userConstants.FACEBOOK_LOGIN_SUCCESS:
      return action.user;
    case userConstants.GOOGLE_LOGIN_SUCCESS:
      return action.user;
    case userConstants.LINKEDIN_LOGIN_SUCCESS:
      return action.user;
    default:
      return state;
  }
}
