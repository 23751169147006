import React from 'react'
import { Button,Form, Modal } from "react-bootstrap";
import Picker, { SKIN_TONE_NEUTRAL } from 'emoji-picker-react';

import { connect } from "react-redux";
import { userfeelActions } from "../../../actions";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TextValidator from '../../TextValidator';
import { ValidatorForm } from 'react-form-validator-core';
import $ from 'jquery';

class CustomFeeling extends React.Component {
  constructor(props) {
        super(props);
		this.state = {chosenEmoji: "", chosenEmojiName: ""};
    }
	onEmojiClick = (event, emojiObject) => {
		if (emojiObject && emojiObject.emoji) {
			/* this.setState({chosenEmoji:emojiObject.emoji, chosenEmojiName:emojiObject.names[0]}); */
			$(".emoji button").removeClass("active");
			if (event.target.parentElement.tagName=="BUTTON") {
				var targetElement= event.target.parentElement;
				targetElement.className += "active";
			}
			this.setState({chosenEmoji:emojiObject.emoji});
		}
  	};
	handleSubmit = (event) => {
		event.preventDefault();
		if (this.state.chosenEmojiName) {
			if (this.props.type=="physically") {
				var data = {
								physically: this.state.chosenEmojiName,
								physicallyEmoji: this.state.chosenEmoji,
							}
			}
			else {
				var data = {
								emotionally: this.state.chosenEmojiName,
								emotionallyEmoji: this.state.chosenEmoji,
							}
			}

			if (this.state.chosenEmoji) {
				//this.props.save(data, this.props.type);
				this.props.customemoji(data);
				//this.props.onHide();

			} else {
				toast.error("Please select your emoji");
			}
		}
		else {
			toast.error("Please select your feelings first"); 
		}
  	}
	handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            ...this.state,
            [name]: value
        });
    }
	componentDidMount() {
    	this.setState({ chosenEmoji: "", chosenEmojiName: "" });
  	}
	render() {
		return (
			<Modal id="custom-feel-modal"
				/* {...this.props} */
				show={this.props.show} onHide={this.props.onHide}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered className="cust-modal" >
				<Modal.Header closeButton>
					<h3 className="cust-heading-center">Custom Feeling</h3>
				</Modal.Header>
			
				<div className="select-emojis-wrap">
					<ValidatorForm className="row profile-form" id="profile-form" onSubmit={this.handleSubmit}>
						<h3>Select emoji</h3>
						<div>
							<Picker onEmojiClick={this.onEmojiClick} skinTone={SKIN_TONE_NEUTRAL} pickerStyle={{ width: '100%' }} groupVisibility={{
								flags: false,
								symbols: false,
								objects: false,
								animals_nature: false,
								food_drink: false,
								travel_places: false,
								activities: false,
								recently_used: false
							}} disableSkinTonePicker={true} />
						</div>
						<div>
							<h4 className="mt-4">Write your feeling:</h4>
							<Form.Group controlId="inputfeeling">
								{/* <Form.Control type="text" placeholder="Fill in... (max 12 characters)"/> */}
								<TextValidator type="text" placeholder="Fill in... (max 12 characters)" name="chosenEmojiName"
								onChange={this.handleChange}
								value={this.state.chosenEmojiName}
								validators={['required', 'maxStringLength:12']}
								errorMessages={['Please write your feeling', 'You can enter max 12 character in this field']} />
							</Form.Group>
						</div>
							<div className="link-btn-wrap">
								<Button onClick={this.props.onHide} className="theme-dark-outline__btn">Close</Button>
								<Button className="theme-dark__btn" type="submit">Save</Button>
							</div>
					
					</ValidatorForm>
				</div>
		
			</Modal>
		);
	}
}

const actionCreators = {
  save: userfeelActions.save, 
};

export default connect(null, actionCreators)(CustomFeeling);