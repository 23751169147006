import { feelConstants } from '../constants';

export function feels(state = [], action) {
  switch (action.type) {
    case feelConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case feelConstants.GETALL_SUCCESS:
      return {
        items: action.feels
      };
    case feelConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}