export const coachingConstants = {
    GETALL_REQUEST: 'COACHING_GETALL_REQUEST',
    GETALL_SUCCESS: 'COACHING_GETALL_SUCCESS',
    GETALL_FAILURE: 'COACHING_GETALL_FAILURE',

    GETROW_REQUEST: 'COACHING_GETROW_REQUEST',
    GETROW_SUCCESS: 'COACHING_GETROW_SUCCESS',
    GETROW_FAILURE: 'COACHING_GETROW_FAILURE',

    SEND_REQUEST: 'COACHING_SESSION_REQUEST',
    SEND_SUCCESS: 'COACHING_SESSION_SUCCESS',
    SEND_FAILURE: 'COACHING_SESSION_FAILURE',
};