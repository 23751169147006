import { authHeader } from '../helpers';
export const userfeelService = {
    getAll,
    save,
    deleteFeel,
};

function getAll(row) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/user-feels?row=${row}`, requestOptions).then(handleResponse);
}

function save(userfeel, type) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(userfeel)
    };
    console.log(type);
    if (type) {
         return fetch(`${process.env.REACT_APP_API_URL}api/user-feels?type=${type}`, requestOptions).then(handleResponse);
    }
    else {
         return fetch(`${process.env.REACT_APP_API_URL}api/user-feels`, requestOptions).then(handleResponse);
    }
   
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function deleteFeel(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API_URL}api/user-feels/${id}`, requestOptions).then(handleResponse);
}