import { gratitudeConstants } from '../constants';

export function gratitudes(state = {}, action) {
  switch (action.type) {
    case gratitudeConstants.GETALL_REQUEST:
      return {
         ...state,
        loading: true
      };
    case gratitudeConstants.GETALL_SUCCESS:
      return {
        items: action.gratitudes.data,
        totalNumberOfRows: action.gratitudes.totalNumberOfRows
      };
      case gratitudeConstants.GETALL_FAILURE:
        return { 
          error: action.error
        };
    case gratitudeConstants.LOAD_MORE_REQUEST:
      return {
         ...state,
        loading: true
      };
    case gratitudeConstants.LOAD_MORE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: [ ...(state.items || []), ...action.gratitudes.data ]
      };
      case gratitudeConstants.LOAD_MORE_FAILURE:
        return { 
          error: action.error
        };
      case gratitudeConstants.DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
     case gratitudeConstants.DELETE_SUCCESS:
      return {
      ...state,
      loading: false,
      items: 
      [
          ...state.items.filter(value => value._id !== action.id)
      ]
    };
    case gratitudeConstants.DELETE_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state
  }
}