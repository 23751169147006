import React from 'react';
import { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

const NotFoundRoute = ({ Component, ...rest }) => {
    const [userRole,setUserRole] = useState(() =>
         (localStorage.getItem('user') !== '' && JSON.parse(localStorage.getItem('user')) )?
            JSON.parse(localStorage.getItem('user')).data.role : null
        )
    if (!userRole ){
        return <Redirect to={'/register'} />
    }

    if ( userRole === '1') { 
        return <Redirect to={'/users'} />
    }

    if (userRole === '2') {
        return <Redirect to={'/dashboard'} />
    }
}

export default NotFoundRoute;
