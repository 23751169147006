import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';
import { Form } from 'react-bootstrap';

class TextValidator extends ValidatorComponent {
    renderValidatorComponent() {
        const { errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;

        return (
            <div>
                <Form.Control {...rest} ref={(r) => { this.input = r; }} />
                {this.errorText()}
            </div>
        );
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <span className="error" style={{ color: 'red' }}>
                {this.getErrorMessage()}
            </span>
        );
    }
}

export default TextValidator;