import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { forgot } from './forgot.reducer';
import { user } from './user.reducer';
import { alert } from './alert.reducer';
import { values } from './value.reducer';
import { interests } from './interest.reducer';
import { gratitudes } from './gratitude.reducer';
import { journals } from './journal.reducer';
import { feels } from './feel.reducer';
import { userfeels } from './userfeel.reducer';
import { contents } from './content.reducer';
import { coaching } from './coaching.reducer';
import { goals } from './goal.reducer';
import { modalReduces } from './modal.reducer';

const rootReducer = combineReducers({
  authentication,
  registration,
  user,
  alert, 
  forgot,
  values,
  interests,
  gratitudes,
  journals,
  feels,
  userfeels,
  contents,
  goals,
  coaching,
  modalReduces
});

export default rootReducer;