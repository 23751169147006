import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Row, Col } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import { connect } from "react-redux";
import { coachingActions } from "../../../actions";
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";

const View = (props) => {

    const handleClose = () => {
        props.handleclose(false);
    }


    const  bioMarkup = () =>  {
        let htmlView = props?.modalviewdata?.acf?.bio_content?.replaceAll("<br />","<span></span>")
        let htmlConverter = {__html: htmlView };
        return htmlConverter;
    }

    const  coachingMarkup = () =>  {
        let htmlView = props?.modalviewdata?.acf?.coaching_approach_content?.replaceAll("<br />","<span></span>")
        let htmlConverter = {__html: htmlView };
        return htmlConverter;
    }
    
    const  accreditMarkup = () =>  {
        let htmlView = props?.modalviewdata?.acf?.accreditations_content?.replaceAll("<br />","<span></span>")
        let htmlConverter = {__html: htmlView };
        return htmlConverter;
    }

    return(
        <Modal
        show={props.show}
        onHide={() => handleClose()}
            size="xl"
            
            centered
            className='coachingViewModal'
        >
            <Modal.Header closeButton className="border-0">
            </Modal.Header>
            <Modal.Body className="mb-4">
            <Row>
                {
                    <Col md="4"  className="mt-2">
                        <Card style={{ width: '100%' }} className="mt-3 position-relative cards-main pr-3 pb-3 pl-3 border-0 mh-100 h-100" >
                            <Card.Body>
                                <img src={props?.modalviewdata?.acf?.image?.sizes?.thumbnail} className="card-main-image"  />
                                <Card.Subtitle className="mb-2 text-muted text-center pb-3">{props?.modalviewdata?.acf?.job_title}</Card.Subtitle>
                                    <Card.Text className="pb-1 font-weight-bold">
                                        {props?.modalviewdata?.acf?.accreditations_title}
                                    </Card.Text>
                                <Card.Text className="pb-4 card-main-content">
                                    <div dangerouslySetInnerHTML={accreditMarkup()} />
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>     
                }
                
            <Col md="8" className="modal-content-main">
                 <h2 className="cust-heading">{ props?.modalviewdata?.title?.rendered }</h2>
                 <p>{props?.modalviewdata?.acf?.short_description}</p>
                <div className="pt-4 modal_tabing pb-5 mb-4">
                       <Tabs
                            defaultActiveKey="Bio" id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="Bio" title="Bio">
                               <div dangerouslySetInnerHTML={bioMarkup()} />
                            </Tab>
                            <Tab eventKey="coaching Approach" title="coaching Approach">
                                 <div dangerouslySetInnerHTML={coachingMarkup()} />
                            </Tab>
                            <Tab eventKey="Testimonials" title="Testimonials" className="tab-testimonials">
                                {
                                    props?.modalviewdata?.acf?.testimonials_select?.length > 0 ?
                                        props?.modalviewdata?.acf?.testimonials_select?.map( ( row ) =>
                                            <div>
                                                <div className="testimonial-tab-inner">
                                                    <p>{ row?.post_content }</p>
                                                <span>
                                                    { row?.post_title }
                                                </span>
                                                </div>
                                                
                                            </div>
                                         )
                                    :''
                                }
                                
                            </Tab>
                        </Tabs>  
                </div>
                <div className="link-btn-wrap text-right modal-close-main">
                <Link to={`/coaching/request-session/${props?.modalviewdata?.id}`} className="theme-dark__btn" >request session</Link>
                </div>
            </Col>

            </Row>
            </Modal.Body>
           
        </Modal>
    )
}

export default View;