import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import { connect } from 'react-redux';
import { modalAction } from '../../../actions';
import { Create } from '../Users';

import { userService } from "../../../services";



const AdminMenuBar = (props) => {

    const [totalUsers, setTotalUsers] = useState(0);
    const [onlyUsers, setOnlyUsers] = useState(0);
    const [onlyAdmins, setOnlyAdmins] = useState(0);

    
    const handleAddNewUser = () => {
        props.hideShowAddUserModal(true);
    }

    const hideShowAddUserModal = (result,refresh = false) => {
        props.hideShowAddUserModal(false);
        handleTotalUsers();
    }

    const hidepopupmodal = (result,refresh = false) => {
        handleTotalUsers();
    }
    

    useEffect(() => {
        handleTotalUsers();
    },[])

    const handleTotalUsers = async () => {
        await userService.getAll().then( (users) => {
            setTotalUsers( users.data.length );
            if( typeof users.data !== 'undefined' ){
                let usersCount = 0;
                let adminCount = 0;
                users.data.map( ( row )  => {
                    if( typeof row.role !== 'undefined' && row.role !== '' && typeof row.role[0] !== 'undefined' && row.role[0].role === "2"  ){
                        usersCount++;
                    }
                    if( typeof row.role !== 'undefined' && row.role !== '' && typeof row.role[0] !== 'undefined' && row.role[0].role === "1" ){
                        adminCount++;
                    }
                })

                setOnlyUsers(usersCount)
                setOnlyAdmins(adminCount)

            }


        }); 
    }




    return (
        <div className="page__sidebar wrap-sidebar">
            <Navbar expand="lg" className="sidebar-nav">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
               
                <Navbar.Collapse id="theme-navbar-nav" className="w-100">
                    <Nav className="w-100">
                     <NavLink href="/coaching" className="navbar__link user-sidebar_heading mb-2" activeClassName="navbar__link--active" to="/users" >
                           {/*  <span className="menu-ico-home"></span> */}
                            <h2>ALL USERS ({totalUsers}) </h2>
                            
                        </NavLink>
                        <NavLink href="/coaching" className="navbar__link user-sidebar-link d-flex justify-content-between align-items-center" activeClassName="navbar__link--active" to="/users/1" >
                           {/*  <span className="menu-ico-home"></span> */}
                            <p>Admin</p>
                            <p>{onlyAdmins}</p>
                        </NavLink>
                        <NavLink href="/coaching" className="navbar__link user-sidebar-link  d-flex justify-content-between align-items-center" activeClassName="navbar__link--active" to="/users/2">
                            {/* <span className="menu-ico-Goals"></span> */}
                            <p>User</p>
                            <p>{onlyUsers}</p>
                        </NavLink>
                    </Nav>
                </Navbar.Collapse>

                <div className="footer w-100">
                    <button className='theme-dark__btn w-100' onClick={(e) => handleAddNewUser() } >Add New</button>
                </div>
            </Navbar>
            <Create show={props.showAddUserModal} hideShowAddUserModal={hideShowAddUserModal} hidepopupmodal={() => hidepopupmodal()} />
        </div>
    )
}


const MapStateToProps = state => ({
    showAddUserModal: state.modalReduces.showAddUserModal,
})

const MapDispatchToProps = dispatch => ({
    hideShowAddUserModal : (data) => dispatch(modalAction(data))
})

export default connect(MapStateToProps,MapDispatchToProps)(AdminMenuBar);