import React from "react";
import * as moment from "moment";
var he = require('he');

class GoalAccodians extends React.Component {
      render() {
        const { goalDetails } = this.props;
        return (
           <div id="accordion" className="cust-accordions">
                <div className="card cust-accordions-cell">
                    <button className="card-header card-link">
                        <div className="header-title">
                            <h2>Why are you committed?:</h2>
                        </div>
                    </button>

                    <div id="collapseOne" className="collapse show"
                        data-parent="#accordion">
                        <div className="card-body">
                            <p>{ goalDetails ? he.decode(goalDetails.commitment) : "" }</p>
                        </div>
                    </div>
                </div>
                <div className="card cust-accordions-cell">
                    <button className="card-header collapsed card-link">
                        <div className="header-title">
                            <h2>Goal start date:</h2>
                        </div>
                    </button>

                    <div id="collapseTwo" className="collapse show"
                        data-parent="#accordion">
                        <div className="card-body">
                        <p>{ goalDetails ? moment(goalDetails.startDate).format("MMMM DD, YYYY") : ""}</p>
                        </div>
                    </div>
                </div>

                <div className="card cust-accordions-cell">
                    <button className="card-header collapsed card-link">
                        <div className="header-title">
                            <h2> Goal completion date:</h2>
                        </div>
                    </button>
                    <div id="collapseThree" className="collapse show"
                        data-parent="#accordion">
                        <div className="card-body">
                        <p>{ goalDetails ? moment(goalDetails.completionDate).format("MMMM DD, YYYY") : ""}</p>
                        </div>
                    </div>
                </div>

                <div className="card cust-accordions-cell">
                    <button className="card-header collapsed card-link">
                        <div className="header-title">
                            <h2>  Potential obstacles / roadblocks: </h2>
                        </div>

                    </button>
                    <div id="collapseFour" className="collapse show"
                        data-parent="#accordion">
                        <div className="card-body">
                        <p>{ goalDetails ? he.decode(goalDetails.obstacle) : "" }</p>
                        </div>
                    </div>
                </div>

                <div className="card cust-accordions-cell">
                    <button className="card-header collapsed card-link"
                        data-toggle="collapse"
                        data-target="#collapseFive">
                        <div className="header-title">
                            <h2>How will you celebrate?: </h2>
                        </div>
                    </button>
                    <div id="collapseFive" className="collapse show"
                        data-parent="#accordion">
                        <div className="card-body">
                           <p>{ goalDetails ? he.decode(goalDetails.celebration) : "" }</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default GoalAccodians;
