import { authHeader } from '../helpers';
const axios = require('axios');
export const coachingService = {
    getAll,
    getRow,
    sendRequest
};

function getAll(){
    return axios.get(`https://mygoals.co/wp-json/wp/v2/coaching`).then(res => {
            return { 
                res: res,
            };
        });
}

function getRow(id){
    return axios.get(`https://mygoals.co/wp-json/wp/v2/coaching/${id}`).then(res => {
            return { 
                res: res,
            };
        });
}

function sendRequest(data){
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}api/request-session`, requestOptions).then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}