import React from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import SelectJournalPrompt from "../Pages/Modals/SelectJournalPrompt";

import TextValidator from "../TextValidator";
import { ValidatorForm } from "react-form-validator-core";

import { connect } from "react-redux";
import { journalActions } from "../../actions";
import Header from "../Pages/Header";
import Sidebar from "../Pages/Sidebar";

class Journal extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = { modalShow: false, title: "", description: "" };
  }
  handlePrompt = (value) => {
    this.setState({ title: value, modalShow: false });
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.title && this.state.description) {
      this.props.save(this.state);
      this.setState({ title: "", description: "" });
    }
  };
  render() {
    let modalClose = () => this.setState({ modalShow: false });
    return (
      <div className="page-wrap-main">
        <Header />

        <div className="wrapper-main">
          <Sidebar />

          <div className="page-content">
            <div className="card--white full-height journal-view">
              <div className="cust-heading-wrap">
                <h3 className="cust-heading cust-heading-view">
                  select your journal prompt
                </h3>
                <Link to="/journal/journal-log" className="view__btn">
                  View log
                  <span className="eye-ico"></span>
                </Link>
              </div>

              <div className="journal-post-block">
                <ValidatorForm
                  className="post-form"
                  onSubmit={this.handleSubmit}
                >
                  <div className="journal-custom-post">
                    <div className="journal-select-btn-wrap">
                      <Button
                        onClick={() => this.setState({ modalShow: true })}
                        className="theme-dark__btn select-btn"
                      >
                        Select
                      </Button>
                      <Button className="theme-dark-outline__btn others-btn">
                        Others
                      </Button>

                      <SelectJournalPrompt
                        show={this.state.modalShow}
                        onHide={modalClose}
                        onSelectPrompt={this.handlePrompt}
                      />
                    </div>
                    <TextValidator
                      placeholder="Write Your Custom Journal Prompt:"
                      name="title"
                      onChange={this.handleChange}
                      value={this.state.title}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      defaultValue={this.state.title}
                    />
                    <h2></h2>
                  </div>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <TextValidator
                      placeholder="Type your thoughts or put pen to paper"
                      name="description"
                      onChange={this.handleChange}
                      value={this.state.description}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      defaultValue={this.state.description}
                      as="textarea"
                      className="cust-textarea"
                    />
                  </Form.Group>

                  <Form.Group className="text-right">
                    <Button
                      type="submit"
                      onSubmit={(e) => e.preventDefault()}
                      className="theme-dark__btn submit-btn"
                    >
                      Submit
                    </Button>
                  </Form.Group>
                </ValidatorForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const actionCreators = {
  save: journalActions.save,
};

export default connect(null, actionCreators)(Journal);
