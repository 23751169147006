import React, { useEffect, useState } from "react";
import { Row, ProgressBar, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Header from "../Header";
import Sidebar from "../Sidebar";

import { connect } from "react-redux";
import { userActions } from "../../../actions";
import * as moment from "moment";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import "primereact/resources/primereact.min.css";  
import "primereact/resources/themes/lara-light-indigo/theme.css";  

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { userService } from "../../../services";
import Create from "./Create";
import Update from "./Update";
import AdminMenuBar from "../Sidebar/AdminMenuBar";
import AdminHeader from "../AdminHeader";

const List = (props) => {

  const [data,setData] = useState([])
  const [userCheck,setUserCheck] = useState([]);

  const [updateModal, setUpdateModal] = useState(false);
  const [singleUser,setSingleUser] = useState('');
  const params = useParams();
  const [loginUser,setLoginUser] = useState('');

  useEffect(() => {

      setLoginUser(props.user.data)
      getAllUser()
  },[])

  const getAllUser = async () =>{
      await userService.getAll().then( (users) => {
        setData(users.data)
    }); 
  }

  const deleteConfirm = (data) => {
    let details = data;
    confirmAlert({
      title: 'Are you sure?',
      message: 'You want to delete this user.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
               await userService.deleteUser(details).then( () => {
                  getAllUser();
              });

          }
        },
        {
          label: 'No',
        }
      ]
    });
  };

  const fullName = (name) => {
      let firstname = name.firstName.substring(0,10)
      let lastname = name.lastName.substring(0,10)
      return `${firstname} ${lastname}`
  }

  const handlecloseupdate  = (result,refresh = false) => {
      setUpdateModal(false);
      if( refresh ){
          getAllUser();
      }

  }

  const openUpdateModal = (userDetail) => {
      setUpdateModal(true);
      setSingleUser(userDetail)
  }

    const onCheckUser = (e) =>{
      let { value } = e.target;
      setUserCheck( { ...userCheck,value } );
  }

    return (
        <div className="page-wrap-main">
          <AdminHeader />
          <div className="wrapper-main">
            
            <AdminMenuBar />
            <div className="page-content">
              <div className="card--white full-height goal-view">
                <div className="goal-row-wrap">

                  <div className="card card-user-table pt-2">
                     <table className="table table-user">
                        <thead>
                          <tr>
                            {/* <th width="3%" scope="col"></th> */}
                            <th width="20%" scope="col">USERNAME</th>
                            <th width="19%" scope="col">NAME</th>
                            <th width="24.6%" scope="col">EMAIL</th>
                            <th width="14.5%" scope="col">ROLE</th>
                            <th width="10%" scope="col">STATUS</th>
                            <th width="10%" scope="col">&nbsp;&nbsp;&nbsp;&nbsp;</th>
                          </tr>
                        
                        </thead>
                        <tbody>
                          {
                            data.length > 0?
                                data.map( ( row,i ) => 
                                      
                                        <tr key={i} >
                                        {/*  <td scope="row" width="3%" valign="middle" ><div className="table-checkbox"><input type="checkbox" name="userCheck" value={row._id} onChange={(e) => onCheckUser(e) } /><span className="checkmark"></span></div></td> */}
                                          <td scope="row" width="20%" valign="middle"  height="30%" >{ fullName({firstName:row.firstName,lastName:row.lastName }) }</td>
                                          <td scope="row" width="20%" valign="middle">{ fullName({firstName:row.firstName,lastName:row.lastName }) }</td>
                                          <td scope="row" width="30%" valign="middle" className="email-column">{ row.email }</td>
                                          <td scope="row" width="15%" valign="middle">{ (row?.role[0]?.role === '1' )?'admin': (row?.role[0]?.role === '2' )?'user':'user'  }</td>
                                          <td scope="row" width="10%" valign="middle">{ (row?.isConfirmed )?'active':'not active' }</td>
                                          <td scope="row" width="10%" valign="middle" className="user-edit-panel">
                                            <div className="edit-delete-wrap">
                                            {
                                                ( loginUser.email !== row.email )?
                                                  <React.Fragment >
                                                    <span className="user-delete-ico cp" onClick={(e) => deleteConfirm(row) } ></span>
                                                    <span className="user-edit-ico cp" onClick={(e) => openUpdateModal(row) } ></span>
                                                  </React.Fragment>
                                                :<React.Fragment >
                                              <span className="user-delete-ico cp invisible" ></span>
                                              <span className="user-edit-ico cp invisible"  ></span>
                                                  </React.Fragment>
                                            }
                                            </div>
                                           
                                          </td>
                                        </tr>
                                      
                                 )
                            :''

                          }
                          
                        </tbody>
                      </table>
                      
                  </div>

                </div>
              </div>
            </div>
          </div>
          <Update show={updateModal} handlecloseupdate={handlecloseupdate} row={singleUser} />
        </div>

    );
    
  }
  
  const mapState = (state) => {
      const { user } = state;
      return { user };
  }



export default connect(mapState)(List);
