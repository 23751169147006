import { goalConstants } from "../constants";

export function goals(state = [], action) {
	switch (action.type) {
		case goalConstants.GETALL_REQUEST:
			return {
				loading: true,
			};
		case goalConstants.GETALL_SUCCESS:
			return {
				...state,
				loading: false,
				items: action.goals.data,
				totalNumberOfRows: action.goals.totalNumberOfRows,
			};
		case goalConstants.GETALL_FAILURE:
			return {
				error: action.error,
			};
		case goalConstants.GETALL_CATEGORY_REQUEST:
			return {
				loading: true,
			};
		case goalConstants.GETALL_CATEGORY_SUCCESS:
			return {
				...state,
				loading: false,
				categories: action.categories,
			};
		case goalConstants.GETALL_CATEGORY_FAILURE:
			return {
				error: action.error,
			};
		case goalConstants.GETDETAILS_REQUEST:
			return {
				loading: true,
			};
		case goalConstants.GETDETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				details: action.goal,
			};
		case goalConstants.GETDETAILS_FAILURE:
			return {
				error: action.error,
			};
		case goalConstants.GET_STEPS_REQUEST:
			return {
				...state,
				loading: true,
			};
		case goalConstants.GET_STEPS_SUCCESS:
			return {
				...state,
				loading: false,
				steps: action.steps.data,
				completedStepsAvg: action.steps.completedAvg,
			};
		case goalConstants.GET_STEPS_FAILURE:
			return {
				error: action.error,
			};
		case goalConstants.UPDATE_STEPS_REQUEST:
			return {
				...state,
				loading: true,
			};
		case goalConstants.UPDATE_STEP_SUCCESS:
			return {
				...state,
				details: {
					...state.details,
					steps: [
						...state.details.steps.map((step, i) =>
							step._id === action.step.stepId
								? { ...step, isCompleted: !step.isCompleted }
								: step
						),
					],
					completedStepsAvg: action.step.completedStepsAvg,
				},
			};
		case goalConstants.UPDATE_STEP_FAILURE:
			return {
				error: action.error,
			};
		case goalConstants.LOAD_MORE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case goalConstants.LOAD_MORE_SUCCESS:
			return {
				...state,
				loading: false,
				items: [...(state.items || []), ...action.goals.data],
			};
		case goalConstants.LOAD_MORE_FAILURE:
			return {
				error: action.error,
			};
		case goalConstants.DELETE_GOAL_REQUEST:
			return {
				...state,
				loading: true,
			};
		case goalConstants.DELETE_GOAL_SUCCESS:
			return {
				...state,
				loading: false,
				items: [...state.items.filter((value) => value._id !== action.id)],
			};
		case goalConstants.DELETE_GOAL_FAILURE:
			return {
				error: action.error,
			};
		default:
			return state;
	}
}
