import React from "react";
import { Button, Row, Col } from "react-bootstrap";

import { connect } from "react-redux";
import { gratitudeActions } from "../../actions";
import * as moment from "moment";
import ShowMoreText from "react-show-more-text";
import Header from "../Pages/Header";
import Sidebar from "../Pages/Sidebar";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
var he = require('he');

class GratitudeLog extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      row_pass: 0,
      disabled: false,
      data: [],
    };
    this.row = 0;
    this.rowperpage = 5;
    this.buttonText = "Load More";
    this.loadMore = this.loadMore.bind(this);
  }

  componentDidMount() {
    this.props.getGratitudes(this.row);
  }

  loadMore(e) {
    console.log(this.props.gratitudes.totalNumberOfRows);
    e.preventDefault();
    this.row += this.rowperpage;
    this.props.loadMore(this.row);
    this.buttonText = "Load More";
    if (this.row >= this.props.gratitudes.totalNumberOfRows) {
         this.setState({ disabled: true });
    }
  }

 /*  get finished() {
    if (this.row >= this.props.gratitudes.totalNumberOfRows) {
      return (<li key={'done'} className="no-more-load">No More Gratitude to Load</li>);
    }
    return null;
  } */

  get getButtonText() {
    if (this.props.gratitudes.loading) return 'Loading...';
    if (this.props.gratitudes.error) return 'Error has occurred :(';
    return 'Load more'
  } 

  submit = (e) => {
    let id = e.target.getAttribute('id');
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete this gratitude.',
      buttons: [
        {
          label: 'Yes',
          className: 'theme-dark-outline__btn cust-cancel-btn btn btn-primary',
          onClick: () => this.deleteGratitude(id)
        },
        {
          label: 'No',
          className: 'theme-dark__btn cust-save-btn btn btn-primary'
        }
      ]
    });
  };

  deleteGratitude = (id) => {
    this.props.deleteGratitude(id);
  }

  render() {
    const { gratitudes } = this.props;
    const allGratitudes = gratitudes.items ? gratitudes.items : [];
    return (
      <div className="page-wrap-main">
        <Header />

        <div className="wrapper-main">
          <Sidebar />

          <div className="page-content">
            <div className="card--white full-height gratitude-log-view">
              <div className="cust-head-center-wrap">
                <Button
                  className="back-arrow"
                  onClick={() => this.props.history.goBack()}
                ></Button>
                <span className="top-heading">gratitude log</span>
                <h2 className="cust-heading-center">
                  what are you grateful for today?
                </h2>
              </div>

              { allGratitudes.length > 0 ? 
              (<div className="expand-log-details">
                {gratitudes.items &&
                  gratitudes.items.map((gratitude, index) => (
                    <Row className="expand-row" key={gratitude._id}>
                      <Col md={10} className="expand-log-left">
                        <ShowMoreText
                          lines={1}
                          more="Read More +"
                          less="Read less -"
                          className="expand-log-description"
                          anchorClass="read-more-show pls"
                          expanded={false}
                          width={700}
                        >
                          {gratitude.title ? he.decode(gratitude.title) : ""}
                        </ShowMoreText>
                      </Col>
                      <Col md={2} className="gratitude-right">
                        <span className="expand-log-date">
                          {moment(gratitude.createdAt).format("MMMM DD, YYYY")}
                        </span>
                        <button className="ml-2 delete-ico" id={gratitude._id} onClick={this.submit}></button>
                      </Col>
                    </Row>
                  ))}
              </div>) : (<span className="no-data-found text-center">You have not added your gratitudes yet.</span>)}

              <div className="link-btn-wrap text-center">
                  { this.row < gratitudes.totalNumberOfRows  && gratitudes.totalNumberOfRows > 5 &&
                    <button className="theme-dark-outline__btn load-more-btn" onClick={this.loadMore} disabled={this.state.disabled}>
                      {this.getButtonText}
                    </button>
                  }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { gratitudes } = state;
  return { gratitudes };
}

const actionCreators = {
  getGratitudes: gratitudeActions.getAll,
  loadMore: gratitudeActions.loadMore,
  deleteGratitude: gratitudeActions.deleteGratitude,
};

export default connect(mapState, actionCreators)(GratitudeLog);
