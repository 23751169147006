import React from 'react';
import { Button, Form, Modal } from "react-bootstrap";

import { connect } from 'react-redux';
import { interestActions, userActions } from '../../../actions';

class AddInterestPassion extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			userInterests: [],
			custom: ''
		}
		this.hanldeSubmit = this.hanldeSubmit.bind(this);
	}

	componentDidMount() {
		this.props.getinterests();
		this.setState({userInterests: this.props.user.data.interests});
	}

	// handle button click event and pass data in parent
	hanldeSubmit() {
		if (this.state.custom || this.state.userInterests) {
			if (this.state.custom) {
				var interests = {
					"interests": this.state.userInterests,
					"custom": this.state.custom,
				}
				this.props.updateinterest(interests);
				this.setState({...this.state, custom : ''});
			}
			else {
				var interests = {
					"interests": this.state.userInterests,
				}
				this.props.updateinterest(interests);
			}
		}
		this.props.onHide();
	}

	onChangeInterest = (e) => {
		// check if the check box is checked or unchecked
		if (e.target.checked) {
			let values = {'_id': e.target.id, 'title':  e.target.value};
			this.setState({
       			 userInterests: [...this.state.userInterests, values]
    		});
		} else {
			 this.setState({
        		userInterests : this.state.userInterests.filter(function(obj) {return obj._id !== e.target.id;})
      		})
		}
	}

	handleChangeCustom = (event) => {
		const { name, value } = event.target;
		 this.setState({ 
			...this.state,
            [name]: value
        });
		//this.setState({userInterests: this.props.user.data.interests});
	}
	submitInterestSettings = (interest) => {
		this.setState({
			...this.state,
        	userInterests : this.state.userInterests.filter(function(obj) {return obj._id != interest._id}),
      	});
	}

	render() {
		const { user, interests } = this.props;
		return (
			<Modal
				/* {...this.props} */
				show={this.props.show} onHide={this.props.onHide}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered className="cust-modal multiselectcheck-modal addinterest-modal">
				<Modal.Header closeButton>
					<h3 className="cust-heading-center">Add interests and passions</h3>
				</Modal.Header>

				<div className="multiselectcheck-modal-body">
					{interests.items &&
							<Form className="multiselectcheck-modal-Form">
								{interests.items.map((interest, index) =>
									<div key={interest._id} className="custom-checkbox">
										{['checkbox'].map((type) => (
											<div key={`custom-${type}`}>
												<Form.Check
													custom
													type={type}
													id={`${interest._id}`}
													label={`${interest.title}`}
													value={`${interest.title}`}
													defaultChecked={user.data.interests.some(function(userInterest){ return userInterest._id === interest._id})}
													onClick={this.onChangeInterest}
												/>
											</div>
										))}
									</div>
								)}
								<Form.Group controlId="custom" className="custom-field-add">
									<Form.Control type="text" placeholder="+ Custom" name="custom" autoComplete="off" onChange={this.handleChangeCustom} />
								</Form.Group>
							</Form>
                		}
				</div>

				<Modal.Footer className="justify-content-center">
				  <Button onClick={this.props.onHide} className="theme-dark-outline__btn cust-cancel-btn">Cancel</Button>
				  <Button className="theme-dark__btn cust-save-btn" onClick={this.hanldeSubmit}>Save</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

function mapState(state) {
    const { interests, user } = state;
    return { interests, user };
}

const actionCreators = {
    getinterests: interestActions.getAll,
	updateinterest: userActions.updateInterest,
}

export default connect(mapState, actionCreators, null, {forwardRef:true})(AddInterestPassion);